import Container from '@mui/material/Container'
import React from 'react'
import ProductNewEditForm from './product-edit-form'

export default function ProductCreateView() {
    return (
        <Container maxWidth={'xl'}>
            <ProductNewEditForm />
        </Container>
    )
}
