import { OrderItem } from 'src/service/types/order'
import { TableContainer } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import { OrderItemRow, OrderItemRowHead } from './order-item-row'
import React from 'react'
import { TableHeadCustom } from '../../../components/table'

type Props = {
    items: OrderItem[]
}

export const OrderItemsTable = ({ items }: Props) => {
    return <TableContainer>
        <Table>
            <TableHeadCustom headLabel={OrderItemRowHead} />
            <TableBody>
                {items.map((item, index) => <OrderItemRow key={index} item={item} />)}
            </TableBody>
        </Table>
    </TableContainer>
}