import { Period } from '../service/types/report'
import { PaymentType } from '../service/types/payment-type'

export const globalPeriods = [
    { value: Period.DAY, label: 'Gün' },
    { value: Period.WEEK, label: 'Hafta' },
    { value: Period.MONTH, label: 'Ay' },
    { value: Period.YEAR, label: 'Yıl' },
] as const

export function getPeriodLabel(period: Period) {
    return globalPeriods.find(it => it.value === period)?.label
}

export function getPaymentCardIcon(cardType: string): string | undefined {
    const clearCartType = cardType.toLowerCase().replaceAll('-', '').replaceAll('_', '').replaceAll(' ', '')
    switch (clearCartType) {
        case 'mastercard':
            return 'logos-mastercard'
        case 'visa':
            return 'logos-visa'
        case 'amex':
            return 'logos-amex'
        case 'paypal':
            return 'logos-paypal'
        default:
            return undefined
    }
}

export function getPaymentLabel(paymentType: PaymentType) {
    return paymentType.name
}