import React, { useEffect } from 'react'
import RearrangeView from '../re-arrange/rearrange-view'
import { SequencedResource } from '../../service/types/sequence'
import { useLocation } from 'react-router-dom'
import { useRouter } from '../../routes/hooks'

export default function CategoryRearrangeView() {
    const departmentId = useLocation().pathname.split('/')[3]
    const router = useRouter()
    useEffect(() => {
        if (!departmentId) {
            router.back()
        }
    }, [departmentId])
    return (
        <RearrangeView resource={SequencedResource.categories}
                       search={departmentId} />
    )
}