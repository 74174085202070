import React from 'react'
import Icon from '@mui/icons-material/LocationCity'
import { BooleanField, Datagrid, TextField } from 'react-admin'
import { ListPagination } from '../common/Pagination'
import { RaFixedList } from '../common/ra-fixed-list'

export const CityIcon = Icon

export const CityList = () => (
    <RaFixedList sort={{ field: 'id', order: 'DESC' }} pagination={<ListPagination />} perPage={50}>
        <Datagrid bulkActionButtons={false}>
            <TextField source={'name'} />
            <BooleanField source={'active'} />
        </Datagrid>
    </RaFixedList>
)

export const CityTranslations = {
    tr: {
        cities: {
            name: 'Şehirler',
            general: 'Genel',
            fields: {
                name: 'Adı',
                location: 'Hizmet Bölgeleri',
                active: 'Aktif',
            },
        },
    },
    en: {
        cities: {
            name: 'Şehirler',
            general: 'Genel',
            fields: {
                name: 'İsim',
                location: 'Hizmet Bölgeleri',
                active: 'Aktif',
            },
        },
    },
}
