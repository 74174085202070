import React from 'react'
import { Dialog, DialogActions, DialogContent } from '@mui/material'
import { CustomDialogTitle } from '../../components/custom-dialog/title/custom-dialog-title'
import { useOrderService } from '../../service/order-service'
import { useNotify } from 'react-admin'
import { useBoolean } from '../../hooks/use-boolean'
import { LoadingButton } from '@mui/lab'

type Props = {
    id: string,
    open: boolean,
    onClose: (success: boolean) => void
}

export const OrderDeliverDialog = ({ id, open, onClose }: Props) => {
    const orderService = useOrderService()
    const notify = useNotify()
    const loading = useBoolean(false)
    const handleDeliver = async () => {
        try {
            loading.onTrue()
            await orderService.deliverOrder(id)
            notify('Sipariş teslim edildi')
            onClose(true)
        } catch (e) {
            notify('Sipariş teslim edilemedi', { type: 'error' })
            loading.onFalse()
        }
    }

    return (
        <Dialog fullWidth open={open} onClose={() => {
            onClose(false)
        }}>
            <CustomDialogTitle onClose={() => {
                onClose(false)
            }} title={'Siparis Tamamla'} />
            <DialogContent sx={{ p: 2 }}>
                Bu siparişi teslim etmek istediğinizden emin misiniz?
            </DialogContent>
            <DialogActions>
                <LoadingButton loading={loading.value}
                               onClick={handleDeliver}
                               color="success">
                    {loading.value ? 'Sipariş Teslim Ediliyor' : 'Sipariş Teslim Et'}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}