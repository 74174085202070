import React, { useEffect, useState } from 'react'
import CardHeader from '@mui/material/CardHeader'
import Card from '@mui/material/Card'
import { CardActions } from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh'
import { useNotify, useTranslate } from 'react-admin'
import { LoadingButton } from '@mui/lab'
import { useOrderService } from '../../service/order-service'
import { Order } from '../../service/types/order'
import OrderTable from '../../features/order/order-table'
import { ApiAuthProvider } from '../../service/api-auth-provider'
import { useLiveOrderService } from '../../service/live-order-service'

export default function PendingOrder() {
    const translate = useTranslate()
    const [loading, setLoading] = useState(false)
    const [orders, setOrders] = useState<Order[]>([])
    const notify = useNotify()
    const service = useOrderService()

    const updateOrders = (order: Order) => {
        const index = orders.findIndex((o) => o.id === order.id)
        const updated = [...orders]
        if (index !== -1) {
            updated[index] = order
            setOrders(updated)
        } else {
            setOrders((prev) => [order, ...prev])
        }
    }
    const removeOrder = (order: Order) => {
        const index = orders.findIndex((o) => o.id === order.id)
        if (index !== -1) {
            const updated = [...orders]
            updated.splice(index, 1)
            setOrders(updated)
        }
    }

    useLiveOrderService({
        onNewOrder: updateOrders,
        onConfirmOrder: updateOrders,
        onCanceledOrder: removeOrder,
        onDeliveredOrder: removeOrder,
    })

    const loadData = async () => {
        const auth = ApiAuthProvider.INSTANCE.getSession()
        if (!auth) {
            return
        }
        setLoading(true)
        await service.getPending()
            .then((res) => {
                setOrders(res)
            })
            .catch(() => {
                notify('Siparişler yüklenirken bir hata oluştu', { type: 'error' })
                setOrders([])
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        const interval = setInterval(() => {
            void loadData()
        }, 120_000)
        void loadData()
        return () => {
            clearInterval(interval)
        }
    }, [])

    return (
        <Card
            sx={{
                flex: 1,
                m: 1,
            }}
            variant={'outlined'}
        >
            <CardHeader
                action={
                    <CardActions>
                        <LoadingButton
                            startIcon={<RefreshIcon />}
                            loading={loading}
                            color={'primary'}
                            variant={'soft'}
                            onClick={() => {
                                void loadData()
                            }}
                        >
                            Yenile
                        </LoadingButton>
                    </CardActions>
                }
                title={translate('dashboard.pendingOrder.title')}
            />
            <OrderTable orders={orders} refresh={() => {
                void loadData()
            }} />

        </Card>
    )
}

export const PendingOrderTranslations = {
    tr: {
        dashboard: {
            pendingOrder: {
                title: 'Bekleyen Siparişler',
                noData: 'Sipariş Yok!',
            },
        },
    },
}
