import React, { useEffect, useRef } from 'react'
import { Dialog, DialogActions } from '@mui/material'
import { Order } from '../../service/types/order'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { OrderShowButton } from '../pending/order-confirm-button'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

type Props = {
    order: Order,
    onClose: VoidFunction
}
export default function NewOrderDialog({ order, onClose }: Props) {
    const playerRef = useRef<HTMLAudioElement>(null)

    useEffect(() => {
        if (!playerRef.current) {
            return
        }
        if (order) {
            playerRef.current.loop = true
            playerRef.current?.play()?.catch(() => {
                console.error('notification sound play error')
            })
        } else {
            playerRef.current?.pause()
        }
        return () => {
            playerRef.current?.pause()
        }
    }, [order, playerRef])
    return <>
        <Dialog open={order !== null}
                PaperProps={{
                    sx: {
                        position: 'absolute',
                        top: 10,
                        right: 10,
                        borderRadius: 1,
                    },
                }}
                onClose={onClose}>
            <DialogTitle sx={{ p: 1.5 }}>Yeni Sipariş - {order.customer.name}</DialogTitle>
            <DialogContent>
                <Typography variant={'body1'}>
                    {`${order.customer.name} adlı müşteriden yeni bir sipariş geldi`}
                </Typography>
            </DialogContent>
            <DialogActions sx={{ p: 1.5 }}>
                <Button onClick={onClose} color={'error'}>Kapat</Button>
                <OrderShowButton id={order.id} onClick={() => {
                    onClose()
                }} />
            </DialogActions>
        </Dialog>
        <audio ref={playerRef} autoPlay={true} style={{ display: 'none' }} src="/assets/notification.mp3" />
    </>

}