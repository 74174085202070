import { SxProps, Theme } from '@mui/material/styles'
import TableCell from '@mui/material/TableCell'
import EmptyContent from '../empty-content'
import React from 'react'
import { Box } from '@mui/material'

type Props = {
    notFound: boolean
    sx?: SxProps<Theme>
}

export default function TableNoData({ notFound, sx }: Props) {
    return (
        <Box>
            {notFound ? (
                <EmptyContent
                    filled
                    title={'Veri Bulunamadı'}
                    sx={{
                        py: 10,
                        ...sx,
                    }}
                />
            ) : (
                <TableCell colSpan={12} sx={{ p: 0 }} />
            )}
        </Box>
    )
}
