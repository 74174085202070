import React from 'react'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { OrderItem } from '../../../service/types/order'
import { fCurrencyTl } from '../../../utils/format-number'
import TextField from '@mui/material/TextField'
import { useNotify } from 'react-admin'
import Button from '@mui/material/Button'

type Props = {
    item: OrderItem,
    onChange: (item: OrderItem) => void
    onDeleteRow: () => void
}

export const OrderEditItemRowHead = [
    { id: 'product', label: 'Ürün', width: 200 },
    { id: 'quantity', label: 'Adet', width: 100, align: 'center' },
    { id: 'price', label: 'Fiyat', width: 100, align: 'center' },
    { id: 'discount', label: 'İndirimli Fiyat', width: 100, align: 'center' },
    { id: 'total', label: 'Toplam', width: 100, align: 'center' },
    { id: 'actions', label: 'İşlemler', width: 100, align: 'center' },
]

export const OrderEditItemRow = ({ item, onChange, onDeleteRow }: Props) => {
    const usedPrice = item.discount ?? item.price
    const notify = useNotify()
    const onQuantityChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        if (value === '') {
            onChange({
                ...item,
                quantity: 0,
            })
            return
        }
        if (isNaN(parseInt(value))) {
            return
        }
        onChange({
            ...item,
            quantity: parseInt(value),
        })
    }

    function handleQuantityEnter() {
        const quantity = item.quantity
        if (quantity <= 0) {
            notify('Miktar sıfırdan büyük olmalıdır')
        }
    }

    const onKeyPress = async (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            handleQuantityEnter()
            e.preventDefault()
        }
    }

    return <TableRow>
        <TableCell sx={{ width: 200 }}>{item.product.name}</TableCell>
        <TableCell sx={{ width: 100, textAlign: 'center' }}>
            <TextField label={'Miktar'}
                       onKeyDown={onKeyPress}
                       onChange={onQuantityChange}
                       disabled={item.product.id === '-1'}
                       name={'quantity'}
                       size={'small'}
                       inputMode={'numeric'}
                       value={item.quantity}
                       placeholder={'Mk.'} />
        </TableCell>
        <TableCell sx={{ width: 100, textAlign: 'center' }}>{fCurrencyTl(item.price)}</TableCell>
        <TableCell sx={{ width: 100, textAlign: 'center' }}>{fCurrencyTl(item.discount ?? 0)}</TableCell>
        <TableCell sx={{ width: 100, textAlign: 'center' }}>{fCurrencyTl(usedPrice * item.quantity)}</TableCell>
        <TableCell sx={{ width: 100, textAlign: 'center' }}><Button onClick={onDeleteRow} variant={'contained'}
                                                                    size={'small'}
                                                                    color={'error'}>Sil</Button></TableCell>
    </TableRow>
}