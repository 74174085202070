import axios, { AxiosInstance } from 'axios'
import { ApiAuthProvider } from './api-auth-provider'
import { useMemo } from 'react'
import { useLogout, useNotify } from 'react-admin'
import { globalConfig } from '../global-config'

export const useRestService = () => {
    const logout = useLogout()
    const notify = useNotify()
    const api = useMemo(() => {
        const api = createAxios(logout, notify)
        return { api }
    }, [])

    return api.api
}

export function createAxios(logout?: VoidFunction, notify?: (message: string) => void): AxiosInstance {
    const api = axios.create({ baseURL: globalConfig.api.base })
    api.interceptors.request.use(async (request) => {
        await ApiAuthProvider.INSTANCE.applyAuthorizer(request)
        request.headers['Accept-Language'] = 'tr'
        return request
    })

    api.interceptors.response.use(
        (response) => {
            return response
        },
        (error) => {
            const response = error.response
            if (response && response.status >= 400) {
                if (response.status === 401 && ApiAuthProvider.INSTANCE.getSession()) {
                    if (logout) {
                        notify && notify('Oturumunuz sona erdi, lütfen tekrar giriş yapın.')
                        logout()
                        return
                    }
                }
                const data = response.data
                let error: RestError
                if (data?.message) {
                    error = new RestError(data.message, response.status)
                } else {
                    error = new RestError(`Teknik bir aksaklık var! [${response.status}]`, response.status)
                }
                throw error
            } else {
                throw new Error('Teknik bir aksaklık var, lütfen tekrar deneyin ve internet bağlantınızı kontrol edin.')
            }
        },
    )
    return api
}

export class RestError extends Error {
    readonly status: number

    constructor(message: string, status: number) {
        super(message)
        this.name = 'RestError'
        this.status = status
    }
}
