export const OrderReportTranslations = {
    tr: {
        orderReport: {
            name: 'Sipariş Raporu',
            title: 'Başlık',
            count: 'Adet',
            revenue: 'Gelir',
            cost: 'Maliyet',
            profit: 'Kar',
            commission: 'Komisyon',
            commissionRate: 'Komisyon Oranı',
            cashOrders: 'Nakit Siparişler',
            cashAmount: 'Nakit Tutar',
            onlineOrders: 'Online Siparişler',
            onlineAmount: 'Online Tutar',
        },
    },

}