import React from 'react'
import { Dialog, DialogActions, DialogContent } from '@mui/material'
import { CustomDialogTitle } from '../../components/custom-dialog/title/custom-dialog-title'
import { useOrderService } from '../../service/order-service'
import { useNotify } from 'react-admin'
import { useBoolean } from '../../hooks/use-boolean'
import { LoadingButton } from '@mui/lab'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { PaymentInfo } from '../../service/types/order'
import Alert from '@mui/material/Alert'

type Props = {
    id: string,
    open: boolean,
    paymentInfo?: PaymentInfo,
    onClose: (success: boolean) => void
}

export const OrderCancelDialog = ({ id, open, onClose, paymentInfo }: Props) => {
    const orderService = useOrderService()
    const notify = useNotify()
    const loading = useBoolean(false)
    const [reason, setReason] = React.useState('')

    const handleDeliver = async () => {
        try {
            loading.onTrue()
            await orderService.cancelOrder(id, reason)
            notify('Sipariş iptal edildi')
            onClose(true)
        } catch (e) {
            notify(`Sipariş iptal edilemedi. [${e.message}]`, { type: 'error' })
            loading.onFalse()
        }
    }

    return (
        <Dialog fullWidth open={open} onClose={() => {
            onClose(false)
        }}>
            <CustomDialogTitle onClose={() => {
                onClose(false)
            }} title={'Siparis Iptal Et'} />
            <DialogContent sx={{ p: 2 }}>
                <Stack spacing={2}>
                    <TextField
                        fullWidth
                        label="İptal sebebi"
                        value={reason}
                        onChange={(e) => {
                            setReason(e.target.value)
                        }}
                    />
                    <Typography>
                        İptal sebebi siparişin neden iptal edildiğini belirtmek için kullanılır. Bu siparişi iptal etmek
                        emin misiniz?
                    </Typography>
                    {paymentInfo &&
                        <Alert severity={'warning'}>Bu siparişin ödemesi online olarak yapılmaktadır. Ödemeyi otomatik
                            olarak iade etmeye çalışacağız. Ödemeyi iade edemezsek sipariş iptal edilmeyecektir.</Alert>
                    }
                </Stack>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    loading={loading.value}
                    onClick={handleDeliver}
                    color="error">
                    {loading.value ? 'Sipariş İptal Ediliyor' : 'Sipariş İptal Et'}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}