import { m } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import List from '@mui/material/List'
import Stack from '@mui/material/Stack'
import Drawer from '@mui/material/Drawer'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { useBoolean } from 'src/hooks/use-boolean'
import { useResponsive } from 'src/hooks/use-responsive'
import Iconify from 'src/components/iconify'
import Scrollbar from 'src/components/scrollbar'
import { varHover } from 'src/components/animate'
import NotificationItemView from './notification-item-view'
import Badge from '@mui/material/Badge'
import { NotificationItem } from '../../service/types/common'
import Button from '@mui/material/Button'

type Props = {
    notifications: NotificationItem[]
    onMarkAllAsRead: () => void
}
export default function NotificationsPopover({ notifications, onMarkAllAsRead }: Props) {
    const drawer = useBoolean()
    const smUp = useResponsive('up', 'sm')
    const [hasPermission, setHasPermission] = useState(false)

    function askNotificationPermission() {
        if (!('Notification' in window)) {
            console.log('This browser does not support notifications.')
            return
        }
        void Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                setHasPermission(true)
            }
        })
    }

    useEffect(() => {
        if (!('Notification' in window)) {
            console.log('This browser does not support notifications.')
            return
        }
        if (Notification.permission === 'granted') {
            setHasPermission(true)
        }
    }, [])


    const totalUnRead = notifications.filter((item) => item.unread).length

    const renderHead = (
        <Stack spacing={1} sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}>
            <Stack direction="row" alignItems="center">
                <Typography variant="h6" sx={{ flexGrow: 1 }}>
                    Bildirimler
                </Typography>

                {!smUp && (
                    <IconButton onClick={drawer.onFalse}>
                        <Iconify icon="mingcute:close-line" />
                    </IconButton>
                )}
            </Stack>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
                <Typography variant="body2" color="text.secondary">
                    {hasPermission ? 'Masaüstü bildirimleri açık' : 'Masaüstü bildirimleri almak için izin ver'}
                </Typography>
                {!hasPermission &&
                    <Button variant={'soft'} size={'small'} color={'primary'} onClick={askNotificationPermission}>
                        Izin Ver
                    </Button>}
            </Stack>
        </Stack>
    )

    const renderList = (
        <Scrollbar>
            <List disablePadding>
                {notifications.map((notification) => (
                    <NotificationItemView key={notification.id} notification={notification} />
                ))}
            </List>
        </Scrollbar>
    )

    return (
        <>
            <IconButton
                component={m.button}
                whileTap="tap"
                whileHover="hover"
                variants={varHover(1.05)}
                color={drawer.value ? 'primary' : 'default'}
                onClick={() => {
                    onMarkAllAsRead()
                    drawer.onTrue()
                }}
            >
                <Badge badgeContent={totalUnRead} color="error">
                    <Iconify icon="solar:bell-bing-bold-duotone" width={24} />
                </Badge>
            </IconButton>

            <Drawer
                open={drawer.value}
                onClose={drawer.onFalse}
                anchor="right"
                slotProps={{
                    backdrop: { invisible: true },
                }}
                PaperProps={{
                    sx: { width: 1, maxWidth: 420 },
                }}
            >
                {renderHead}

                <Divider />

                {renderList}
            </Drawer>
        </>
    )
}
