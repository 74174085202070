import { BaseCrudService } from '../base-crud-service'
import { Category } from '../types/category'
import { AxiosInstance } from 'axios'
import { useRestService } from '../base-service'

export const useCategoryService = () => {
    const api = useRestService()
    return new CategoryService(api)
}

class CategoryService extends BaseCrudService<Category,void> {

    constructor(api: AxiosInstance) {
        super(api, '/categories')
    }
}