import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'
import { OrderReportItem } from '../../service/types/report'
import { fCurrency, fNumber, fPercent } from '../../utils/format-number'
import React from 'react'


type Props = {
    row: OrderReportItem;
    showCost: boolean;
};

export default function OrderReportTableRow({
                                                row,
                                                showCost,
                                            }: Props) {
    const {
        count,
        revenue,
        cost,
        profit,
        commission,
        commissionRate,
        title,
        cashOrders,
        onlineOrders,
        onlineAmount,
        cashAmount,
    } = row

    return (
        <>
            <TableRow hover>
                <TableCell>
                    <Typography variant="subtitle2" noWrap>
                        {title}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant="subtitle2" noWrap>
                        {fNumber(count)}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant="subtitle2" noWrap>
                        {fCurrency(revenue)}
                    </Typography>
                </TableCell>
                {showCost && <TableCell>
                    <Typography variant="subtitle2" noWrap>
                        {fCurrency(cost)}
                    </Typography>
                </TableCell>
                }
                {showCost && <TableCell>
                    <Typography variant="subtitle2" noWrap>
                        {fCurrency(profit)}
                    </Typography>
                </TableCell>
                }
                <TableCell>
                    <Typography variant="subtitle2" noWrap>
                        {fNumber(cashOrders)}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant="subtitle2" noWrap>
                        {fCurrency(cashAmount)}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant="subtitle2" noWrap>
                        {fNumber(onlineOrders)}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant="subtitle2" noWrap>
                        {fCurrency(onlineAmount)}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant="subtitle2" noWrap>
                        {fCurrency(commission)}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant="subtitle2" noWrap>
                        {fPercent(commissionRate)}
                    </Typography>
                </TableCell>
            </TableRow>
        </>
    )
}
