import React from 'react'
import Icon from '@mui/icons-material/Apartment'
import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    DeleteWithConfirmButton,
    Edit,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TabbedForm,
    TextField,
} from 'react-admin'
import { ListPagination } from '../common/Pagination'
import { RaFixedList } from '../common/ra-fixed-list'

export const DistrictIcon = Icon

export const DistrictList = () => {
    return <RaFixedList sort={{ field: 'id', order: 'DESC' }} pagination={<ListPagination />} perPage={50}>
        <Datagrid bulkActionButtons={false}>
            <TextField source={'name'} />
            <TextField source={'cityName'} />
            <BooleanField source={'active'} />
            <DeleteWithConfirmButton />
        </Datagrid>
    </RaFixedList>
}

export const DistrictCreate = () => (
    <Create redirect={'list'}>
        <SimpleForm redirect={'list'}>
            <ReferenceInput name={'remoteId'} source={'remoteId'}
                            reference={'remoteDistricts'}>
                <SelectInput optionText={'name'} source={'remoteId'} name={'remoteId'} />
            </ReferenceInput>
        </SimpleForm>
    </Create>
)

export const DistrictEdit = () => (
    <Edit>
        <TabbedForm redirect={'list'} syncWithLocation={false}>
            <TabbedForm.Tab label={'resources.districts.general'}>
                <BooleanInput source={'active'} name={'active'} />
            </TabbedForm.Tab>
        </TabbedForm>
    </Edit>
)

export const DistrictTranslations = {
    en: {
        districts: {
            name: 'Districts',
            general: 'General',
            fields: {
                name: 'Name',
                location: 'Location',
                active: 'Active',
                remoteId: 'Select District',
                cityName: 'City',
            },
        },
    },
    tr: {
        districts: {
            name: 'İlçeler',
            general: 'Genel',
            fields: {
                name: 'Adı',
                location: 'Konum',
                remoteId: 'İlçe Seçin',
                active: 'Aktif',
                cityName: 'Şehir',
            },
        },
    },
}
