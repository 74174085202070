import { AxiosInstance } from 'axios'
import { useRestService } from './base-service'

export const useAppConfigService = () => {
    const api = useRestService()
    return new AppConfigService(api)
}

class AppConfigService {
    private readonly api: AxiosInstance

    constructor(api: AxiosInstance) {
        this.api = api
    }

    async getSystemStatus(): Promise<boolean> {
        return await this.api.get('/appConfigs/systemStatus/get')
            .then(({ data }) => {
                return data.value === 'true'
            })
    }

    async updateSystemStatus(enable: boolean): Promise<boolean> {
        return await this.api.post(`/appConfigs/systemStatus/update?enable=${enable}`)
            .then(({ data }) => {
                return data.value === 'true'
            })
    }

}