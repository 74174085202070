import download from 'downloadjs'
import { stringify } from 'querystring'
import { globalConfig } from './global-config'


export function isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n)
}

export function serverExport(resource, sort, filter, pdf = false, onDone, absolute = '') {
    const query = {
        sort: sort ? `${sort.field},${sort.order}` : undefined,
        filter: JSON.stringify(filter),
    }
    const s = stringify(query)
    let url = `${globalConfig.api.base}/${resource}/export/${pdf ? 'pdf' : 'csv'}?${s}`
    if (absolute) {
        url = encodeURI(absolute)
    }
    downloadFile(url, onDone, pdf)
}

export function downloadFile(url, onDone, pdf) {
    let x = new XMLHttpRequest()
    x.open('GET', url, true)
    x.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('nerde.market.token')}`)
    x.setRequestHeader('Access-Control-Allow-Origin', '*')
    x.setRequestHeader('X-API-KEY', globalConfig.api.apiKey)
    x.responseType = 'blob'
    x.onload = function(e) {
        let filename = ''
        let disposition = e.currentTarget.getResponseHeader('Content-Disposition')
        if (disposition && disposition.indexOf('inline') !== -1) {
            let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
            let matches = filenameRegex.exec(disposition)
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '')
            }
        }
        let fileType = pdf ? 'application/pdf' : 'text/csv'
        download(e.target.response, filename, fileType)
        onDone()
    }
    x.send()
}
