
export type TimeRange = {
    start: number;
    end: number;
}

export type PaymentMethodReport = {
    methodId: string;
    methodName: string;
    count: number;
    total: number;
}

export type DeliveryReport = {
    driver: string;
    count: number;
    total: number;
    deliveryFees: number;
}

export type SalesReport = {
    period: TimeRange;
    total: number;
    profit: number;
    deliveryFees: number;
    commission: number;
    commissionRate: number;
    cost: number;
    revenue: number;
    paymentMethods: PaymentMethodReport[];
    deliveries: DeliveryReport[];
    cancelledOrders: number;
}

export type OrderCountStatistics = {
    title: string;
    cancelled: number;
    completed: number;
    confirmed: number;
}

export type IncomeItem = {
    title: string;
    total: number;
    time: TimeRange;
}

export type IncomeReport = {
    period: TimeRange;
    earnings: IncomeItem[];
    expenses: IncomeItem[];
    profit: IncomeItem[];
    totalEarnings: number;
    totalExpenses: number;
    totalProfit: number;
    trendEarnings: number;
    trendExpenses: number;
    trendProfit: number;

}

export type OrderReportItem = {
    title: string;
    count: number;
    profit: number;
    revenue: number;
    cost: number;
    commission: number;
    commissionRate: number;
    cashOrders: number;
    cashAmount: number;
    onlineOrders: number;
    onlineAmount: number;
    time: TimeRange;
}
export type OrderReport = {
    totalOrders: number;
    totalRevenue: number;
    totalCost: number;
    totalProfit: number;
    totalCommission: number;
    commissionRate: number;
    totalOnlineOrders: number;
    totalCashOrders: number;
    totalCashAmount: number;
    totalOnlineAmount: number;
    period: TimeRange;
    items: OrderReportItem[];
}


export enum Period {
    DAY = 'DAY', WEEK = 'WEEK', MONTH = 'MONTH', YEAR = 'YEAR'
}