import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { fDateTime } from 'src/utils/format-time'
import Iconify from 'src/components/iconify'
import React from 'react'
import { OrderStatusLabel } from '../order-row'
import { Order, OrderStatus } from '../../../service/types/order'
import { useRouter } from '../../../routes/hooks'
import { OrderRowActions } from '../../../dashboard/pending/order-confirm-button'


type Props = {
    status: OrderStatus;
    orderNumber: string;
    createdAt: number;
    order: Order;
    onRefresh: () => void;
};

export default function OrderDetailsToolbar({
                                                status,
                                                createdAt,
                                                orderNumber,
                                                order,
                                                onRefresh,
                                            }: Props) {
    const router = useRouter()

    return (
        <>
            <Stack
                spacing={3}
                direction={{ xs: 'column', md: 'row' }}
                sx={{
                    mb: { xs: 3, md: 5 },
                }}
            >
                <Stack spacing={1} direction="row" alignItems="flex-start">
                    <IconButton onClick={() => {
                        router.back()
                    }}>
                        <Iconify icon="eva:arrow-ios-back-fill" />
                    </IconButton>

                    <Stack spacing={0.5}>
                        <Stack spacing={1} direction="row" alignItems="center">
                            <Typography
                                variant="h4"> Siparis {orderNumber} </Typography>
                            <OrderStatusLabel status={status} />
                        </Stack>

                        <Typography variant="body2" sx={{ color: 'text.disabled' }}>
                            {fDateTime(createdAt)}
                        </Typography>
                    </Stack>
                </Stack>

                <Stack
                    flexGrow={1}
                    spacing={1.5}
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                >

                    <OrderRowActions order={order} onRefresh={onRefresh} disableShow={true} />
                </Stack>
            </Stack>

        </>
    )
}
