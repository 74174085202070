import React, { useState } from 'react'
import { useController } from 'react-hook-form'
import Stack, { StackProps } from '@mui/material/Stack'
import Iconify from '../components/iconify'
import Box from '@mui/material/Box'
import Placeholder from '../images/placeholder.png'
import { LoadingButton } from '@mui/lab'
import Dialog from '@mui/material/Dialog'
import { CustomDialogTitle } from '../components/custom-dialog/title/custom-dialog-title'
import DialogContent from '@mui/material/DialogContent'
import { useBoolean } from '../hooks/use-boolean'
import Typography from '@mui/material/Typography'
import { useUploadService } from '../service/upload-service'

type Props = {
    source: string
    required: boolean
} & StackProps
export default function ImageUploadInput({ source, required, ...rest }: Props) {
    const [uploading, setUploading] = useState(false)
    const inputRef = React.useRef<HTMLInputElement>(null)
    const dialogOpen = useBoolean(false)
    const service = useUploadService()
    const onDrop = async (event: any) => {
        const files = event.target.files
        if (files !== undefined && files.length > 0) {
            await upload(files[0])
        }
    }
    const { field } = useController({
        name: source,
        rules: { required },
    })

    const upload = async (file: File) => {
        try {
            setUploading(true)
            const url = await service.uploadImage(file)
            field.onChange(url)
            setUploading(false)
        } catch (e) {
            setUploading(false)
        }
    }

    const onRemove = async () => {
        try {
            setUploading(true)
            await service.deleteImage(field.value)
            field.onChange(null)
        } catch (e) {
            setUploading(false)
        } finally {
            setUploading(false)
        }
    }
    const chooseFile = () => {
        inputRef.current?.click()
    }
    const isSelected = field?.value !== null && field.value !== undefined

    return (
        <>
            <Stack {...rest}>
                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                    <Box height={60} width={60}
                         overflow={'hidden'}
                         borderRadius={1}
                         onClick={isSelected ? dialogOpen.onTrue : () => {
                         }}
                         sx={{ cursor: isSelected ? 'pointer' : 'default' }}
                         display={'flex'} justifyContent={'center'}
                         alignItems={'center'}>
                        <img alt={''} src={isSelected ? field.value : Placeholder} width={'100%'} height={'100%'} />
                    </Box>
                    <input type={'file'} ref={inputRef} onChange={onDrop} accept={'image/*'}
                           style={{ display: 'none' }} />
                    <LoadingButton onClick={isSelected ? onRemove : chooseFile}
                                   loading={uploading}
                                   sx={{ width: 100 }}
                                   startIcon={<Iconify
                                       icon={isSelected ? 'solar:gallery-remove-bold' : 'solar:upload-bold-duotone'} />}
                                   variant={'soft'} size={'medium'}
                                   color={isSelected ? 'error' : 'success'}>
                        {isSelected ? 'Sil' : 'Yükle'}
                    </LoadingButton>
                </Stack>
                {required && !isSelected &&
                    <Typography variant={'caption'} color={'error'}>Resim yüklemek zorunludur</Typography>}
            </Stack>
            {field.value && <ImageViewDialog url={field.value} open={dialogOpen.value} onClose={dialogOpen.onFalse} />}
        </>
    )
}

type ImageViewDialogProps = {
    url: string
    open: boolean
    onClose: VoidFunction

}

export const ImageViewDialog = ({ url, open, onClose }: ImageViewDialogProps) => {
    return <Dialog open={open} onClose={onClose} maxWidth={'md'} fullWidth>
        <CustomDialogTitle title={'Resim'} onClose={onClose} />
        <DialogContent sx={{ p: 2 }}>
            <img alt={''} src={url} style={{ maxWidth: '100%', maxHeight: '100%' }} />
        </DialogContent>
    </Dialog>
}
