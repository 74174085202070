import React from 'react'
import CustomPopover, { usePopover } from '../components/custom-popover'
import { getPeriodLabel, globalPeriods } from '../utils/period+'
import MenuItem from '@mui/material/MenuItem'
import { Period } from '../service/types/report'
import ButtonBase from '@mui/material/ButtonBase'
import Iconify from '../components/iconify'

type Props = {
    period: Period
    onPeriodChange: (period: Period) => void
}

export const PeriodInputView = ({ period, onPeriodChange }: Props) => {
    const popover = usePopover()

    return (
        <>
            <ButtonBase
                onClick={popover.onOpen}
                sx={{
                    pl: 1,
                    py: 0.5,
                    pr: 0.5,
                    borderRadius: 1,
                    typography: 'subtitle2',
                    bgcolor: 'background.neutral',
                }}
            >
                {getPeriodLabel(period)}

                <Iconify
                    width={16}
                    icon={popover.open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                    sx={{ ml: 0.5 }}
                />
            </ButtonBase>

            <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 140 }}>
                {globalPeriods.map((option) => (
                    <MenuItem
                        key={option.value}
                        selected={option.value === period}
                        onClick={() => {
                            onPeriodChange(option.value)
                        }}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </CustomPopover>
        </>
    )
}