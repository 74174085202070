import { AxiosInstance } from 'axios'
import { DefaultPageQuery, Page, Query } from './types/common'
import { DeepPartial } from 'react-hook-form'

export class BaseCrudService<T, INSERT> {
    protected readonly api: AxiosInstance
    protected readonly resource: string

    constructor(api: AxiosInstance, resource: string) {
        this.api = api
        this.resource = resource
    }

    public async getAll({ query, pagination = DefaultPageQuery }: Query): Promise<T[]> {
        const filter = query ? JSON.stringify(query) : ''
        return await this.api.get<Page<T>>(`${this.resource}/get`, {
            params: { filter, ...pagination },
        }).then((response) => response.data.content)
    }

    public async get(id: string): Promise<T> {
        return await this.api.get<T>(`${this.resource}/getOne/${id}`).then((response) => response.data)
    }

    public async insert(data: INSERT): Promise<T> {
        return await this.api.post<T>(`${this.resource}/insert`, data).then((response) => response.data)
    }

    public async update<UPDATE extends DeepPartial<INSERT>>(id: string, data: UPDATE): Promise<T> {
        return await this.api.put<T>(`${this.resource}/update/${id}`, data).then((response) => response.data)
    }

}