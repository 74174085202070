import * as Yup from 'yup'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography'
import FormProvider, { RHFTextField } from 'src/components/hook-form'
import { useAccountService } from '../../service/account-service'
import Alert from '@mui/material/Alert'
import { useBoolean } from '../../hooks/use-boolean'
import ImageUploadInput from '../../common/image-upload-input'
import { UserProfile } from '../../service/types/user'
import { Message } from '../../service/types/common'

export default function AccountGeneral() {
    const [message, setMessage] = useState<Message>(null)
    const loading = useBoolean(false)
    const accountService = useAccountService()
    const [profile, setProfile] = useState<UserProfile | null>(null)
    const UpdateUserSchema = Yup.object().shape({
        name: Yup.string().required('Adınızı giriniz'),
        phone: Yup.string().required('Telefon numaranızı giriniz'),
        address: Yup.string().required('Adresinizi giriniz'),
        facebook: Yup.string().url('Geçerli bir URL giriniz'),
        twitter: Yup.string().url('Geçerli bir URL giriniz'),
        instagram: Yup.string().url('Geçerli bir URL giriniz'),
        website: Yup.string().url('Geçerli bir URL giriniz'),
        description: Yup.string().required('Açıklamanızı giriniz').required('Açıklamanızı giriniz'),
        email: Yup.string().email('Geçerli bir e-posta adresi giriniz').required('E-posta adresinizi giriniz'),
    })

    const defaultValues = useMemo(() => {
        return {
            name: profile?.name ?? '',
            phone: profile?.phone ?? '',
            address: profile?.address ?? '',
            facebook: profile?.facebook ?? '',
            twitter: profile?.twitter ?? '',
            instagram: profile?.instagram ?? '',
            website: profile?.website ?? '',
            description: profile?.description ?? '',
            email: profile?.email ?? '',
            image: profile?.image ?? '',
        }
    }, [profile])

    const methods = useForm({
        resolver: yupResolver(UpdateUserSchema),
        defaultValues,
    })

    const {
        handleSubmit,
        reset,
        formState: { isSubmitting },
    } = methods

    useEffect(() => {
        reset(defaultValues)
    }, [profile])

    useEffect(() => {
        const fetchProfile = async () => {
            loading.onTrue()
            await accountService
                .getProfile()
                .then(setProfile)
                .catch((e) => {
                    setMessage({ severity: 'error', text: e.message })
                })
                .finally(loading.onFalse)
        }
        void fetchProfile()

    }, [])

    const onSubmit = handleSubmit(async (data) => {
        try {
            setMessage(null)
            const updated = { ...data }
            await accountService.updateUser(updated)
            setMessage({ severity: 'success', text: 'Başarıyla güncellendi' })
            setTimeout(() => {
                setMessage(null)
            }, 2000)
        } catch (error) {
            setMessage({ severity: 'error', text: error.message })
        }
    })

    return (
        <FormProvider methods={methods} onSubmit={onSubmit}>
            <Grid container spacing={3}>
                <Grid xs={12} md={4}>
                    <Card sx={{ pt: 10, pb: 10, px: 3, textAlign: 'center' }}>
                        <Stack alignItems={'center'} justifyContent={'center'} spacing={1}>
                            <ImageUploadInput sx={{}} required={false} source={'image'} />
                            <Typography variant={'h6'}>{profile?.name}</Typography>
                            <Typography variant={'body2'}>{profile?.phone}</Typography>
                            <Typography variant={'body2'}>{profile?.address}</Typography>
                        </Stack>
                    </Card>
                </Grid>

                <Grid xs={12} md={8}>
                    <Card sx={{ p: 4, pt: 6 }}>
                        <Box
                            rowGap={3}
                            columnGap={2}
                            display="grid"
                            gridTemplateColumns={{
                                xs: 'repeat(1, 1fr)',
                                sm: 'repeat(2, 1fr)',
                            }}
                        >
                            <RHFTextField
                                name="facebook"
                                label="Facebook"
                                disabled={message?.severity === 'success' || loading.value}
                            />
                            <RHFTextField
                                name="twitter"
                                label={'Twitter'}
                                disabled={message?.severity === 'success' || loading.value}
                            />
                            <RHFTextField
                                name="website"
                                label={'Website'}
                                disabled={message?.severity === 'success' || loading.value}
                            />
                            <RHFTextField
                                name="instagram"
                                label={'Instagram'}
                                disabled={message?.severity === 'success' || loading.value}
                            />
                            <RHFTextField
                                name="email"
                                label={'E-posta'}
                                disabled={message?.severity === 'success' || loading.value} />
                        </Box>
                        <RHFTextField
                            name="description"
                            label={'Hakkında'}
                            fullWidth
                            multiline
                            sx={{ mt: 2 }}
                            minRows={4}
                            disabled={message?.severity === 'success' || loading.value} />
                        {message && (
                            <Alert severity={message.severity} sx={{ mt: 2 }}>
                                {message.text}
                            </Alert>
                        )}

                        <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
                            <LoadingButton
                                type="submit"
                                color={'primary'}
                                variant="contained"
                                disabled={message?.severity === 'success'}
                                loading={isSubmitting}
                            >
                                {isSubmitting ? 'Güncelleniyor...' : 'Güncelle'}
                            </LoadingButton>
                        </Stack>
                    </Card>
                </Grid>
            </Grid>
        </FormProvider>
    )
}
