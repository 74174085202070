import { AxiosInstance } from 'axios'
import { OrderReport, Period } from './types/report'
import { useRestService } from './base-service'

export const useOrderReportService = () => {
    const api = useRestService()
    return new OrderReportService(api)
}

class OrderReportService {

    private readonly api: AxiosInstance

    constructor(api: AxiosInstance) {
        this.api = api
    }

    async getReport(start: number, end: number, period: Period): Promise<OrderReport> {
        return await this.api.get(`/report/orders/get?start=${start}&end=${end}&period=${period}`).then(it => it.data)
    }
}