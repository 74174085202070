import React, { cloneElement } from 'react'
import Icon from '@mui/icons-material/ContactSupport'
import {
    Button,
    Create,
    CreateButton,
    Datagrid,
    DeleteWithConfirmButton,
    Edit,
    EditButton,
    ExportButton,
    required,
    RichTextField,
    sanitizeListRestProps,
    TabbedForm,
    TextField,
    TextInput,
    TopToolbar,
    useListContext,
} from 'react-admin'
import OrderIcon from '@mui/icons-material/ViewQuilt'
import { Link as RouterLink } from 'react-router-dom'
import { FormatButtons, ListButtons, RichTextInput, RichTextInputToolbar } from 'ra-input-rich-text'
import { RaFixedList } from '../../common/ra-fixed-list'

export const FaqIcon = Icon
const ListActions = (props) => {
    const { className, exporter, filters, maxResults, ...rest } = props
    const { currentSort, resource, displayedFilters, filterValues, hasCreate, basePath, showFilter, total } =
        useListContext()
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters &&
                cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                })}
            <Button color="primary" label={'Rearrange Order'} component={RouterLink} to="/faq/rearrange">
                <OrderIcon />
            </Button>
            <CreateButton basePath={basePath} />
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                maxResults={maxResults}
            />
        </TopToolbar>
    )
}

const CreateOrEdit = () => (
    <TabbedForm syncWithLocation={false} redirect={'list'}>
        <TabbedForm.Tab label={'English'}>
            <TextInput source={'question.english'} fullWidth validate={required()} />
            <RichTextInput
                source={'answer.english'}
                fullWidth
                validate={required()}
                toolbar={
                    <RichTextInputToolbar>
                        <FormatButtons />
                        <ListButtons />
                    </RichTextInputToolbar>
                }
                multiline
            />
        </TabbedForm.Tab>
        <TabbedForm.Tab label={'Turkish'}>
            <TextInput source={'question.turkish'} fullWidth validate={required()} />
            <RichTextInput
                source={'answer.turkish'}
                toolbar={
                    <RichTextInputToolbar>
                        <FormatButtons />
                        <ListButtons />
                    </RichTextInputToolbar>
                }
                fullWidth
                validate={required()}
                multiline
            />
        </TabbedForm.Tab>
    </TabbedForm>
)

export const FaqCreate = (props) => (
    <Create {...props}>
        <CreateOrEdit />
    </Create>
)

export const FaqEdit = (props) => (
    <Edit {...props}>
        <CreateOrEdit />
    </Edit>
)

export const FaqList = () => (
    <RaFixedList actions={<ListActions />}>
        <Datagrid bulkActionButtons={false}>
            <TextField source={'question.turkish'} />
            <RichTextField source={'answer.turkish'} />
            <EditButton />
            <DeleteWithConfirmButton />
        </Datagrid>
    </RaFixedList>
)

export const FaqTranslations = {
    en: {
        faq: {
            name: 'FAQ',
            fields: {
                question: {
                    english: 'English Question',
                    turkish: 'Turkish Question',
                },
                answer: {
                    english: 'English Answer',
                    turkish: 'Turkish Answer',
                },
            },
        },
    },

    tr: {
        faq: {
            name: 'SSS',
            fields: {
                question: {
                    english: 'Ingilizce Soru',
                    turkish: 'Turkce Sorular',
                },
                answer: {
                    english: 'Ingilizce Cevap',
                    turkish: 'Turkce Cevap',
                },
            },
        },
    },
}
