import React from 'react'
import { useSettingsContext } from '../../components/settings'

const LightTheme = React.lazy(() => import('./date-input-light-theme'))
const DarkTheme = React.lazy(() => import('./date-input-dark-theme'))

const ThemeSelector = ({ children }) => {
    const CHOSEN_THEME = useSettingsContext().themeMode
    return (
        <>
            <React.Suspense fallback={<></>}>
                {CHOSEN_THEME === 'light' && <LightTheme />}
                {CHOSEN_THEME === 'dark' && <DarkTheme />}
            </React.Suspense>
            {children}
        </>
    )
}
export default ThemeSelector
