import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import CardHeader from '@mui/material/CardHeader'
import { fCurrencyTl } from 'src/utils/format-number'
import Scrollbar from 'src/components/scrollbar'
import { OrderItem } from '../../../service/types/order'
import React from 'react'
import TextField from '@mui/material/TextField'
import { OrderEditItemsTable } from './order-edit-items-table'
import { useBoolean } from '../../../hooks/use-boolean'
import { Product } from '../../../service/types/product'
import { AddProductDialog } from './add/add-product-dialog'
import Button from '@mui/material/Button'
import Iconify from '../../../components/iconify'
import { useNotify } from 'react-admin'
import Toolbar from '@mui/material/Toolbar'
import AppBar from '@mui/material/AppBar'

type Props = {
    shipping: number;
    onShippingChange: (shipping: number) => void;
    items: OrderItem[];
    onUpdateTap: () => void;
    onItemsChange: (items: OrderItem[]) => void;
};

export default function OrderEditDetailsItems({
                                                  items,
                                                  shipping,
                                                  onShippingChange,
                                                  onItemsChange,
                                                  onUpdateTap,
                                              }: Props) {
    const subTotal = items.reduce((acc, item) => acc + item.price * item.quantity, 0)
    const totalAmount = subTotal + (shipping || 0)
    const addOpen = useBoolean(false)
    const notify = useNotify()
    const onShippingChangeEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        const newShipping = value ? parseInt(value) : 0
        onShippingChange(newShipping)
    }

    const onAddProduct = (product: Product) => {
        const isExist = items.find(item => item.product.id === product.id)
        if (isExist) {
            notify('Bu ürün zaten ekli', { type: 'error' })
            return
        }
        const newItems = [...items]
        newItems.push({
            product: {
                id: product.id,
                name: product.name.turkish,
                image: product.image,
            },
            quantity: 1,
            price: product.price,
        })
        onItemsChange(newItems)
        addOpen.onFalse()
    }
    const renderTotal = (
        <Stack
            spacing={2}
            alignItems="flex-end"
            sx={{ my: 3, textAlign: 'right', typography: 'body2' }}
        >
            <Stack direction="row">
                <Box sx={{ color: 'text.secondary' }}>Toplam</Box>
                <Box sx={{ width: 160, typography: 'subtitle2' }}>{fCurrencyTl(subTotal) || '-'}</Box>
            </Stack>

            <Stack direction="row" alignItems={'center'}>
                <Box sx={{ color: 'text.secondary' }}>Teslim Ücreti</Box>
                <Box
                    sx={{
                        width: 160,
                        ...(shipping && { color: 'error.main' }),
                    }}
                >
                    <TextField name={'shipping'}
                               size={'small'}
                               sx={{ width: 50 }}
                               value={`${shipping}`}
                               onChange={onShippingChangeEvent}
                               placeholder={'Teslim Ücreti'} />
                </Box>
            </Stack>

            <Stack direction="row" sx={{ typography: 'subtitle1' }}>
                <Box>Toplam</Box>
                <Box sx={{ width: 160 }}>{fCurrencyTl(totalAmount) || '-'}</Box>
            </Stack>
        </Stack>
    )

    return (
        <Card>
            <CardHeader title="Ürünler" />

            <Stack
                sx={{
                    px: 3,
                }}
            >
                <Scrollbar>
                    <OrderEditItemsTable items={items} onChange={onItemsChange} />
                </Scrollbar>
                <Box>
                    <Button startIcon={<Iconify icon={'solar:add-circle-bold-duotone'} />}
                            variant={'contained'}
                            onClick={addOpen.onTrue}>
                        Ürün Ekle
                    </Button>
                </Box>

                {renderTotal}
            </Stack>
            <AppBar position="static" color="default" elevation={0}
                    sx={{ borderRadius: 1 }}>
                <Toolbar>
                    <Stack alignItems="flex-end" sx={{ width: '100%' }} spacing={1}>
                        <Button color="primary" variant="contained" onClick={onUpdateTap}>
                            Kaydet
                        </Button>
                    </Stack>
                </Toolbar>
            </AppBar>
            <AddProductDialog open={addOpen.value} onClose={addOpen.onFalse} onAdd={onAddProduct} />
        </Card>
    )
}
