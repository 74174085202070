import { ApexOptions } from 'apexcharts'
import React from 'react'
import Box from '@mui/material/Box'
import CardHeader from '@mui/material/CardHeader'
import Card, { CardProps } from '@mui/material/Card'
import Chart, { useChart } from 'src/components/chart'
import { fNumber } from '../utils/format-number'
import { Period } from '../service/types/report'
import { PeriodInputView } from '../common/period-input-view'

interface Props extends CardProps {
    title?: string;
    subheader?: string;
    period: Period;
    onChartPeriodChange: (period: Period) => void;
    chart: {
        categories?: string[];
        colors?: string[];
        series: Array<{
            name: string;
            data: number[];
        }>;
        options?: ApexOptions;
    };
}

export default function CountStatisticsChart({
                                                 title,
                                                 subheader,
                                                 period,
                                                 onChartPeriodChange,
                                                 chart,
                                                 ...other
                                             }: Props) {
    const { categories, colors, series, options } = chart

    const chartOptions = useChart({
        colors,
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
        },
        xaxis: {
            categories,
        },
        tooltip: {
            y: {
                formatter: (value: number) => fNumber(value),
            },
        },
        ...options,
    })

    return (
        <>
            <Card {...other} >
                <CardHeader
                    title={title}
                    action={<PeriodInputView period={period} onPeriodChange={onChartPeriodChange} />}
                    subheader={subheader} />

                <Box sx={{ mt: 3, mx: 3 }}>
                    <Chart dir="ltr" type="bar" series={series} options={chartOptions} height={364} />
                </Box>
            </Card>
        </>
    )
}
