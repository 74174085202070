import { OrderItem } from 'src/service/types/order'
import { TableContainer } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import React from 'react'
import { TableHeadCustom } from '../../../components/table'
import { OrderEditItemRow, OrderEditItemRowHead } from './order-edit-item-row'

type Props = {
    items: OrderItem[]
    onChange: (items: OrderItem[]) => void
}

export const OrderEditItemsTable = ({ items, onChange }: Props) => {
    const handleItemChange = (index: number, item: OrderItem) => {
        const newItems = [...items]
        newItems[index] = item
        onChange(newItems)
    }
    const handleDeleteRow = (index: number) => {
        onChange(items.filter((_, i) => i !== index))
    }
    return <TableContainer>
        <Table>
            <TableHeadCustom headLabel={OrderEditItemRowHead} />
            <TableBody>
                {items.map((item, index) =>
                    <OrderEditItemRow key={index}
                                      item={item}
                                      onDeleteRow={() => {
                                          handleDeleteRow(index)
                                      }}
                                      onChange={(update: OrderItem) => {
                                          handleItemChange(index, update)
                                      }} />)}
            </TableBody>
        </Table>
    </TableContainer>
}