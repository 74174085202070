import ShowIcon from '@mui/icons-material/VisibilityRounded'
import CreateIcon from '@mui/icons-material/CreateRounded'
import EditIcon from '@mui/icons-material/EditRounded'

import { EmojiEventsRounded, HandymanRounded, SettingsRounded, ToysRounded } from '@mui/icons-material'
import { type BreadItem } from './global-context'
import { type FC } from 'react'
import UserIcon from '@mui/icons-material/AccountCircle'
import { CityIcon } from '../../address/City'
import { DistrictIcon } from '../../address/District'
import { TownIcon } from '../../address/Town'
import SaleIcon from '@mui/icons-material/ShoppingCart'
import DepoSaleIcon from '@mui/icons-material/Store'
import EmailIcon from '@mui/icons-material/Email'
import NotificationIcon from '@mui/icons-material/Notifications'
import ConfigIcon from '@mui/icons-material/Style'
import { FaqIcon } from '../../features/faq/Faq'
import SalePersonIcon from '@mui/icons-material/PersonPin'
import { ProductIcon } from '../../products/Product'
import { CampaignIcon } from '../../products/Campaign'
import { DepartmentIcon } from '../../categories/Department'
import { CategoryIcon } from '../../categories/Category'
import LocationIcon from '@mui/icons-material/LocationOn'
import { OrderIcon } from '../../features/order/order'
import { PaymentIcon } from '../../features/order/payment/payment-list-view'
import SequenceIcon from '@mui/icons-material/SwapVertRounded'

export interface Path {
    id: string
    title: string
    translate: boolean
    icon?: FC<any>
}

const paths: Path[] = [
    {
        id: 'users',
        title: 'resources.users.name',
        icon: UserIcon,
        translate: true,
    },
    {
        id: 'cities',
        title: 'resources.cities.name',
        icon: CityIcon,
        translate: true,
    },
    {
        id: 'districts',
        title: 'resources.districts.name',
        translate: true,
        icon: DistrictIcon,
    },
    {
        id: 'towns',
        title: 'resources.towns.name',
        translate: true,
        icon: TownIcon,
    },
    {
        id: 'orderReport',
        title: 'orderReport.name',
        translate: true,
        icon: OrderIcon,
    },
    {
        id: 'saleReports',
        title: 'resources.saleReports/shop.name',
        icon: SaleIcon,
        translate: true,
    },
    {
        id: 'saleReports',
        title: 'resources.saleReports/depo.name',
        translate: true,
        icon: DepoSaleIcon,
    },
    {
        id: 'notificationEmails',
        title: 'resources.notificationEmails.name',
        translate: true,
        icon: EmailIcon,
    },
    {
        id: 'notifications',
        title: 'resources.notifications.name',
        translate: true,
        icon: NotificationIcon,
    },
    {
        id: 'appConfigs',
        title: 'resources.appConfigs.name',
        translate: true,
        icon: ConfigIcon,
    },
    {
        id: 'faq',
        title: 'resources.faq.name',
        translate: true,
        icon: FaqIcon,
    },
    {
        id: 'refunds',
        title: 'resources.refunds.name',
        translate: true,
        icon: SaleIcon,
    },
    {
        id: 'depoRefunds',
        title: 'resources.depoRefunds.name',
        translate: true,
        icon: DepoSaleIcon,
    },
    {
        id: 'sales',
        title: 'resources.sales.name',
        translate: true,
        icon: SaleIcon,
    },
    {
        id: 'depoSales',
        title: 'resources.depoSales.name',
        translate: true,
        icon: DepoSaleIcon,
    },
    {
        id: 'customers',
        title: 'resources.customers.name',
        translate: true,
        icon: UserIcon,
    },
    {
        id: 'salePersons',
        title: 'resources.salePersons.name',
        translate: true,
        icon: SalePersonIcon,
    },
    {
        id: 'depoSalePersons',
        title: 'resources.depoSalePersons.name',
        translate: true,
        icon: DepoSaleIcon,
    },
    {
        id: 'shopSalePersons',
        title: 'resources.shopSalePersons.name',
        translate: true,
        icon: SaleIcon,
    },
    {
        id: 'products',
        title: 'resources.products.name',
        translate: true,
        icon: ProductIcon,
    },
    {
        id: 'productUnits',
        title: 'resources.productUnits.name',
        translate: true,
        icon: ToysRounded,
    },
    {
        id: 'campaigns',
        title: 'resources.campaigns.name',
        translate: true,
        icon: CampaignIcon,
    },
    {
        id: 'drivers',
        title: 'resources.drivers.name',
        translate: true,
        icon: HandymanRounded,
    },
    {
        id: 'orders',
        title: 'resources.orders.name',
        translate: true,
        icon: OrderIcon,
    },
    {
        id: 'orderUpdate',
        title: 'Update Order',
        translate: false,
        icon: OrderIcon,
    },
    {
        id: 'departments',
        title: 'resources.departments.name',
        translate: true,
        icon: DepartmentIcon,
    },
    {
        id: 'categories',
        title: 'resources.categories.name',
        translate: true,
        icon: CategoryIcon,
    },
    {
        id: 'departmentReorder',
        title: 'departmentOrder.title',
        translate: true,
        icon: DepartmentIcon,
    },
    {
        id: 'addresses',
        title: 'resources.addresses.name',
        translate: true,
        icon: LocationIcon,
    },
    {
        title: 'resources.rankings.name',
        id: 'rankings',
        translate: true,
        icon: EmojiEventsRounded,
    },
    {
        id: 'show',
        title: 'ra.action.show',
        icon: ShowIcon,
        translate: true,
    },
    {
        id: 'create',
        title: 'ra.action.create',
        icon: CreateIcon,
        translate: true,
    },
    {
        id: 'edit',
        title: 'ra.action.edit',
        icon: EditIcon,
        translate: true,
    },
    {
        id: 'profile',
        title: 'Ayarlari',
        icon: SettingsRounded,
        translate: true,
    },
    {
        id: 'rearrange',
        title: 'Sıralama',
        icon: SequenceIcon,
        translate: true,
    },
    {
        id: 'payment-reference',
        title: 'resources.payment-reference.name',
        icon: PaymentIcon,
        translate: true,
    },

]

export function getBreadMatch(pathId: string, end: boolean, displays: Record<string, BreadItem> | undefined): Path {
    let display: BreadItem | undefined
    if (end) {
        display = displays?.[`${pathId}tab`]
    }
    if (!display) {
        display = displays?.[pathId]
    }
    return (
        paths.find((x) => x.id === pathId) ?? {
            id: pathId,
            title: display?.title ?? pathId,
            icon: display?.icon,
            translate: false,
        }
    )
}
