import * as React from 'react'
import { cloneElement } from 'react'
import { CreateButton, sanitizeListRestProps, SelectColumnsButton, TopToolbar } from 'react-admin'
import { ExportButton } from '../common/ExportButton'

export const ProductListActions = ({
                                       currentSort,
                                       className,
                                       resource,
                                       filters,
                                       displayedFilters,
                                       exporter,
                                       filterValues,
                                       permanentFilter,
                                       hasCreate,
                                       basePath,
                                       selectedIds,
                                       onUnselectItems,
                                       showFilter,
                                       maxResults,
                                       total,
                                       translate,
                                       excelExporter,
                                       ...rest
                                   }) => {
    return <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        <SelectColumnsButton />
        {filters &&
            cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
        <CreateButton basePath={basePath} />
        <ExportButton path={'products'} pdf={true} total={total} sort={currentSort} filter={filterValues} />
        <ExportButton path={'products'} pdf={false} total={total} sort={currentSort} filter={filterValues} />
    </TopToolbar>
}
