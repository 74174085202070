import { Controller, useFormContext } from 'react-hook-form'
import TextField from '@mui/material/TextField'
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'
import React from 'react'


interface Props<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: React.ReactNode;
    getOptionId?: (option: T) => string;
}

export type RHFAutocompleteProps<T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined> = Omit<Props<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'>

export default function RHFAutocomplete<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
>({
      name,
      label,
      placeholder,
      helperText,
      getOptionId,
      ...other
  }: RHFAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
    const { control, setValue } = useFormContext()

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <Autocomplete
                    {...field}
                    onChange={(_, newValue) => {
                        if (Array.isArray(newValue)) {
                            const ids = newValue.map((value) => getOptionId !== undefined ? getOptionId(value) : value)
                            setValue(name, ids, { shouldValidate: true })
                        } else if (newValue === null) {
                            setValue(name, '', { shouldValidate: true })
                        } else if (typeof newValue === 'string') {
                            setValue(name, newValue, { shouldValidate: true })
                        } else {
                            setValue(name, getOptionId !== undefined ? getOptionId(newValue as T) : newValue, { shouldValidate: true })
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            label={label}
                            placeholder={placeholder}
                            error={!!error}
                            helperText={error ? error?.message : helperText}
                            {...params}
                        />
                    )}
                    {...other}
                />
            )}
        />
    )
}
