import React, { useEffect } from 'react'
import RearrangeView from '../re-arrange/rearrange-view'
import { SequencedResource } from '../../service/types/sequence'
import { useLocation } from 'react-router-dom'
import { useRouter } from '../../routes/hooks'

export default function ProductRearrangeView() {
    const categoryId = useLocation().pathname.split('/')[3]
    const router = useRouter()
    useEffect(() => {
        if (!categoryId) {
            router.back()
        }
    }, [categoryId])
    return (
        <RearrangeView resource={SequencedResource.products} search={categoryId} />
    )
}