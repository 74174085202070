import React, { Fragment } from 'react'
import { Product } from '../../../../service/types/product'
import List from '@mui/material/List'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'
import Divider from '@mui/material/Divider'


type Props = {
    products: Product[]
    onItemSelected: (product: Product) => void
}

const getActivePrice = (product: Product) => {
    if (product.newPrice) {
        return product.newPrice
    } else {
        return product.price
    }
}

export const SearchedProductListView = ({ products, onItemSelected }: Props) => {
    return <List>
        {products.map(product => {
            return <Fragment key={product.id}>
                <ListItemButton key={product.id} onClick={() => {
                    onItemSelected(product)
                }}>
                    <ListItemText primary={product.name.turkish} secondary={`${getActivePrice(product)} TL`} />
                </ListItemButton>
                <Divider />
            </Fragment>
        })}
    </List>
}