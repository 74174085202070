import React from 'react'
import { useRecordContext } from 'react-admin'
import { fDateTime } from '../utils/format-time'

export const TimeField = ({ source }) => {
    const record = useRecordContext()
    if (record[source] === 0 || record[source] === undefined || record[source] === null) {
        return <span>-</span>
    } else {
        let date = new Date(record[source])
        return <span>{fDateTime(date)}</span>
    }
}
