import Container from '@mui/material/Container'
import React, { useCallback, useEffect } from 'react'
import ProductNewEditForm from './product-edit-form'
import { useProductService } from '../../service/crud/product-service'
import { Product } from '../../service/types/product'
import { useNotify } from 'react-admin'
import LinearProgressView from '../../common/linear-progress-view'

export default function ProductEditView() {
    const id = window.location.hash.split('/').pop() as string
    const productService = useProductService()
    const [product, setProduct] = React.useState<Product>()
    const notify = useNotify()

    const fetchProduct = useCallback(async () => {
        await productService.get(id)
            .then(setProduct)
            .catch((error) => {
                notify(error.message, { type: 'error' })
            })
    }, [])

    useEffect(() => {
        void fetchProduct()
    }, [fetchProduct])

    if (!product) {
        return <LinearProgressView />
    }

    return (
        <Container maxWidth={'xl'}>
            <ProductNewEditForm id={id} currentProduct={product} />
        </Container>
    )
}
