export const DriverTranslations = {
    tr: {
        drivers: {
            name: 'Sürücüler',
            fields: {
                name: 'Adı',
                email: 'E-posta',
                username: 'Telefon',
                enabled: 'Aktif',
                createdAt: 'Oluşturulma Tarihi',
                image: 'Resim',
            },
        },
    },
}