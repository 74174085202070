import React, { useEffect, useState } from 'react'
import { InputAdornment, Popover, TextField } from '@mui/material'
import { addDays } from 'date-fns'
import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import DateRangeIcon from '@mui/icons-material/DateRange'
import { useTranslate } from 'react-admin'
import DateInputThemeSelector from './date-input-theme-selector'
import { useFormContext } from 'react-hook-form'
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { useTheme } from '@mui/material/styles'
import { fDateTime } from '../../utils/format-time'
import Box from '@mui/material/Box'
import trLocale from 'date-fns/locale/tr'

export function DateRangeInput({ onChange, insideFilter, value, ...others }) {
    useEffect(() => {
        if (value && value.start && value.end) {
            setTimeout(() => {
                const update = {
                    startDate: new Date(value.start),
                    endDate: new Date(value.end),
                    key: 'selection',
                }
                setState([update])
                updateText(update)
            }, 500)
        } else if (state && state[0]) {
            updateText(state[0])
        }
    }, [value])
    const [state, setState] = useState([
        {
            startDate: addDays(new Date(), -7),
            endDate: new Date(),
            key: 'selection',
        },
    ])
    const translate = useTranslate()
    const [anchorEl, setAnchorEl] = useState(null)
    const [text, setText] = useState('')
    const theme = useTheme()

    function onDateChange(item) {
        let selection = item.selection
        setState([selection])
        updateText(selection)
        onChange({
            start: selection.startDate.getTime(),
            end: selection.endDate.getTime(),
        })
    }

    function updateText(selection) {
        let start = selection.startDate
        let end = selection.endDate
        if (end === start) {
            //same date and this is happening
            end = new Date(end)
        }
        let s = fDateTime(start)
        let s1 = fDateTime(end)
        setText(`${s} ─ ${s1}`)
    }

    function onHourChange(hour, type) {
        const selection = state[0]
        if (type === '10') {
            selection.startDate = hour
        } else {
            selection.endDate = hour
        }
        setState([selection])
        updateText(selection)
        onChange({
            start: selection.startDate.getTime(),
            end: selection.endDate.getTime(),
        })
    }

    useEffect(() => {
        if (state[0] && !value) {
            onChange({
                start: state[0].startDate.getTime(),
                end: state[0].endDate.getTime(),
            })
        }
    }, [state])

    function formatTime(time) {
        let options = {
            hour: '2-digit',
            minute: '2-digit',
        }
        return time.toLocaleString('tr', options)
    }

    return (
        <DateInputThemeSelector>
            <TextField
                label={translate('dateRangeInput.title')}
                value={text}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position={'start'}>
                            <DateRangeIcon sx={{ color: theme.palette.text.secondary }} />
                        </InputAdornment>
                    ),
                }}
                onClick={(e) => setAnchorEl(e.currentTarget)}
                {...others}
            />
            <Popover
                open={anchorEl != null}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box sx={{
                    background: theme.palette.background.paper,
                    height: '421px',
                }}>
                    <DateRangePicker
                        onChange={onDateChange}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={1}
                        rangeColors={[theme.palette.primary.main]}
                        locale={trLocale}
                        ranges={state}
                        direction="horizontal"
                    />
                    <div className={'rdrCalendarWrapper rdrDateRangeWrapper'} style={{ height: '100%' }}>
                        <div className={'rdrDateDisplayWrapper'}>
                            <div className={'rdrDateDisplay'}>
                                <span className={`rdrDateInput rdrDateDisplayItem rdrDateDisplayItemActive`}>
                                    <input value={formatTime(state[0].startDate)} readOnly />
                                </span>
                                <span className={`rdrDateInput rdrDateDisplayItem rdrDateDisplayItemActive`}>
                                    <input value={formatTime(state[0].endDate)} readOnly />
                                </span>
                            </div>
                        </div>
                        <Box sx={{
                            backgroundColor: theme.palette.background.paper,
                            height: '100%',
                            display: 'flex',
                            padding: '1em',
                            flexDirection: 'column',
                            alignContent: 'center',
                            alignItems: 'center',

                        }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={trLocale}>
                                <TimePicker
                                    label={'Başlangıç saati'}
                                    value={state[0].startDate}
                                    orientation={'portrait'}
                                    size={'small'}
                                    sx={{
                                        backgroundColor: theme.palette.background.paper,
                                        mb: 2,
                                    }}
                                    slotProps={{
                                        textField: { size: 'small' },
                                    }}
                                    onChange={(x) => onHourChange(x, '10')}
                                    slots={{
                                        actionBar: Nothing,
                                        toolbar: Nothing,
                                    }}
                                />
                                <TimePicker
                                    label={'Bitiş saati'}
                                    value={state[0].endDate}
                                    orientation={'portrait'}
                                    slotProps={{
                                        textField: { size: 'small' },
                                    }}
                                    sx={{
                                        backgroundColor: theme.palette.background.paper,
                                    }}
                                    onChange={(x) => onHourChange(x, '11')}
                                    slots={{
                                        actionBar: Nothing,
                                        toolbar: Nothing,
                                    }}
                                />
                            </LocalizationProvider>
                        </Box>
                    </div>
                </Box>
            </Popover>
        </DateInputThemeSelector>
    )
}

const Nothing = () => {
    return <></>
}

export const FormDateRangeInput = ({ source, required, alwaysOn, name, ...props }) => {
    const { trigger, setValue, getValues } = useFormContext()
    return (
        <DateRangeInput
            insideFilter
            value={getValues(source)}
            onChange={(value) => {
                setValue(source, value)
                trigger(source)
            }}
            {...props}
        />
    )
}
export const DateRangeInputTranslations = {
    tr: {
        dateRangeInput: {
            title: 'Tarih aralığı seçin',
            close: 'Kapat',
            apply: 'Uygula',
            startTime: 'Başlangıç saati',
            endTime: 'Bitiş saati',
        },
    },
}
