import React, { useCallback } from 'react'
import Button from '@mui/material/Button'
import { Order } from '../../service/types/order'
import Iconify from '../../components/iconify'
import { Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useBoolean } from '../../hooks/use-boolean'
import { OrderConfirmDialog } from './order-confirm-dialog'
import { OrderCancelDialog } from '../../features/order/order-cancel-dialog'
import { OrderDeliverDialog } from '../../features/order/order-deliver-dialog'
import { useOrderService } from '../../service/order-service'
import { useNotify } from 'react-admin'
import LoadingButton from '@mui/lab/LoadingButton'

type Props = {
    order: Order,
    onRefresh: VoidFunction,
    disableShow?: boolean
}
type ConfirmProps = {
    order: Order,
    onConfirm: (order: Order) => void,
    onDeliver: (order: Order) => void
    onOnWay: (order: Order) => void
    loading?: boolean
}
type CancelProps = {
    order: Order
    onCancel: (order: Order) => void
    disabled?: boolean
}

function OrderConfirmButton({ order, onConfirm, onDeliver, onOnWay, loading }: ConfirmProps) {
    const isPending = order.status === 'PENDING'
    const isCancelled = order.status === 'CANCELLED'
    const isConfirmed = order.status === 'CONFIRMED'
    const isDelivered = order.status === 'DELIVERED'
    const isOnWay = order.status === 'ONWAY'
    return (
        <LoadingButton
            variant={'soft'}
            disabled={isCancelled || isDelivered}
            color={isPending ? 'success' : 'info'}
            loading={loading}
            onClick={() => {
                isPending ? onConfirm(order) : isConfirmed ? onOnWay(order) : onDeliver(order)
            }}
            startIcon={<Iconify icon={'mdi:thumb-up'} />}
        >
            {isPending ? 'Onayla' : isConfirmed ? 'Yola cikti' : isOnWay ? 'Tamamla' : 'Tamamlandı'}
        </LoadingButton>
    )
}

function OrderCancelButton({ order, onCancel }: CancelProps) {
    return (
        <Button
            variant={'soft'}
            disabled={order.status === 'CANCELLED'}
            color={'error'}
            onClick={() => {
                onCancel(order)
            }}
            startIcon={<Iconify icon={'mdi:cancel'} />}
        >
            İptal Et
        </Button>
    )
}

export function OrderShowButton({ id, onClick }: { id: string, onClick?: VoidFunction }) {
    const navigate = useNavigate()
    const show = () => {
        navigate(`/orders/show/${id}`, { state: { id } })
    }
    return (
        <Button
            variant={'soft'}
            color={'info'}
            onClick={() => {
                onClick?.()
                show()
            }}
            startIcon={<Iconify icon={'mdi:eye'} />}
        >
            Göster
        </Button>
    )
}

export const OrderPrintButton = ({ id }: { id: string }) => {
    const loading = useBoolean(false)
    const orderService = useOrderService()
    const notify = useNotify()
    const onTap = useCallback(async () => {
        loading.onTrue()
        try {
            await orderService.printReceipt(id)
        } catch (e) {
            notify('Hata oluştu. Lütfen tekrar deneyin')
        } finally {
            loading.onFalse()
        }
    }, [id])
    return (
        <Button
            color="inherit"
            variant="outlined"
            onClick={onTap}
            disabled={loading.value}
            startIcon={<Iconify icon="solar:printer-minimalistic-bold" />}
        >
            Yazdir
        </Button>
    )
}

export const OrderBillButton = ({ id }: { id: string }) => {
    const loading = useBoolean(false)
    const orderService = useOrderService()
    const notify = useNotify()
    const onTap = useCallback(async () => {
        loading.onTrue()
        try {
            const url = await orderService.getBill(id)
            window.open(url, '_blank')
        } catch (e) {
            notify(`Hata! ${e.message}`)
        } finally {
            loading.onFalse()
        }
    }, [id])
    return (
        <LoadingButton
            color="inherit"
            variant="outlined"
            onClick={onTap}
            loading={loading.value}
            startIcon={<Iconify icon="solar:bill-list-bold" />}
        >
            E-Fatura
        </LoadingButton>
    )
}


export function OrderRowActions({ order, onRefresh, disableShow }: Props) {
    const confirmOpen = useBoolean(false)
    const cancelOpen = useBoolean(false)
    const deliverOpen = useBoolean(false)
    const orderService = useOrderService()
    const loading = useBoolean(false)
    const notify = useNotify()
    const navigate = useNavigate()
    const isPending = order.status === 'PENDING'
    const isCancelled = order.status === 'CANCELLED'
    const isDelivered = order.status === 'DELIVERED'
    const isCompleted = isCancelled || isDelivered
    const canCancel = isPending || (!isCancelled && !isDelivered)

    const onAction = (success: boolean) => {
        if (success) {
            onRefresh()
        }
        confirmOpen.onFalse()
        cancelOpen.onFalse()
        deliverOpen.onFalse()
    }

    const markOnWay = async (order: Order) => {
        loading.onTrue()
        await orderService.onWayOrder(order.id)
            .then(() => {
                onRefresh()
            }).catch(() => {
                notify('Hata oluştu. Lütfen tekrar deneyin')
            }).finally(loading.onFalse)
    }

    return (
        <>
            <Stack direction={'row'} spacing={1}>
                {!disableShow && <OrderShowButton id={order.id} />}
                {!isCompleted &&
                    <OrderConfirmButton order={order}
                                        loading={loading.value}
                                        onConfirm={confirmOpen.onTrue} onOnWay={markOnWay}
                                        onDeliver={deliverOpen.onTrue} />}
                {canCancel && <OrderCancelButton disabled={loading.value} order={order} onCancel={cancelOpen.onTrue} />}
                <OrderPrintButton id={order.id} />
                {!isCompleted &&
                    <Button color="inherit" disabled={loading.value} variant="contained"
                            onClick={() => {
                                navigate(`/orders/edit/${order.id}`, { state: { id: order.id } })
                            }}
                            startIcon={<Iconify icon="solar:pen-bold" />}>
                        Edit
                    </Button>
                }
                {isDelivered && <OrderBillButton id={order.id} />}
            </Stack>
            {confirmOpen.value &&
                <OrderConfirmDialog id={order.id} open={confirmOpen.value} deliveryTime={order.suitableTime} onClose={onAction} />}
            {cancelOpen.value &&
                <OrderCancelDialog id={order.id} open={cancelOpen.value} onClose={onAction}
                                   paymentInfo={order.paymentInfo} />}
            {deliverOpen.value &&
                <OrderDeliverDialog id={order.id} open={deliverOpen.value} onClose={onAction} />}
        </>
    )
}