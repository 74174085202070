import React from 'react'
import Icon from '@mui/icons-material/Category'
import {
    Create,
    Datagrid,
    DeleteWithConfirmButton,
    Edit,
    EditButton,
    Filter,
    ImageField,
    maxLength,
    ReferenceField,
    ReferenceInput,
    required,
    SelectInput,
    Show,
    ShowButton,
    SimpleShowLayout,
    TabbedForm,
    TextField,
    TextInput,
} from 'react-admin'
import { ListPagination } from '../common/Pagination'
import { SearchInput } from '../common/SearchInput'
import { RaFixedList } from '../common/ra-fixed-list'
import ImageUploadInput from '../common/image-upload-input'
import { DefaultListActions } from '../common/default-list-action'
import RearrangeButtonView from '../common/rearrange-button-view'

export const CategoryIcon = Icon
export const CategoryCreate = () => (
    <Create>
        <CreateOrEdit />
    </Create>
)

export const CategoryEdit = () => (
    <Edit>
        <CreateOrEdit />
    </Edit>
)

const CategoryFilters = (props) => (
    <Filter {...props}>
        <SearchInput source={'name.english'} alwaysOn label={'English Name'} />
        <SearchInput source={'name.turkish'} alwaysOn label={'Turkish Name'} />
        <ReferenceInput source={'department.id'} perPage={1000} reference={'departments'}>
            <SelectInput optionText={'name.turkish'} />
        </ReferenceInput>
    </Filter>
)
export const CategoryList = () => {
    return <RaFixedList
        sort={{ field: 'id', order: 'DESC' }}
        pagination={<ListPagination />}
        perPage={50}
        actions={<DefaultListActions/>}
        filters={<CategoryFilters />}
    >
        <CategoryGrid />
    </RaFixedList>
}

export const CategoryGrid = () => (
    <Datagrid bulkActionButtons={false}>
        <TextField source={'name.turkish'} />
        <ReferenceField source={'departmentId'} reference={'departments'}>
            <TextField source={'name.turkish'} />
        </ReferenceField>
        <TextField source={'description.turkish'} />
        <RearrangeButtonView path={'products'}>
             Ürünleri Sırala
        </RearrangeButtonView>
        <ShowButton />
        <EditButton />
        <DeleteWithConfirmButton />
    </Datagrid>
)
export const CategoryShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField source={'name.english'} />
            <TextField source={'name.turkish'} />
            <TextField source={'description.english'} />
            <TextField source={'description.turkish'} />
            <ReferenceField source={'departmentId'} reference={'departments'}>
                <TextField source={'name.turkish'} />
            </ReferenceField>
            <ImageField source={'image'} />
        </SimpleShowLayout>
    </Show>
)
const CreateOrEdit = () => (
    <TabbedForm redirect={'list'} syncWithLocation={false}>
        <TabbedForm.Tab label={'resources.categories.general'}>
            <TextInput source={'name.english'} validate={[required(), maxLength(255)]} />
            <TextInput source={'name.turkish'} validate={[required(), maxLength(255)]} />

            <ReferenceInput source={'departmentId'} perPage={1000} reference={'departments'}>
                <SelectInput optionText={'name.turkish'} validate={required()} />
            </ReferenceInput>
            <TextInput source={'description.english'} fullWidth validate={[required(), maxLength(1000000)]} />
            <TextInput source={'description.turkish'} fullWidth validate={[required(), maxLength(1000000)]} />
            <ImageUploadInput source={'image'} required={true} />
        </TabbedForm.Tab>
    </TabbedForm>
)


export const CategoryTranslations = {
    tr: {
        categories: {
            name: 'Kategoriler',
            general: 'Genel',
            fields: {
                name: {
                    english: 'İngilizce Adı',
                    turkish: 'Türkçe Adı',
                },
                image: 'Resim',
                description: {
                    english: 'İngilizce Açıklama',
                    turkish: 'Türkçe Açıklama',
                },
                department: {
                    id: 'Departman',
                },
            },
        },
    },
}
