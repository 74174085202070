import { ProfileUpdateRequest, UserProfile } from './types/user'
import { AxiosInstance } from 'axios'
import { useRestService } from './base-service'

export const useAccountService = () => {
    const api = useRestService()
    return new AccountService(api)
}

class AccountService {
    private readonly api: AxiosInstance

    constructor(api: AxiosInstance) {
        this.api = api
    }


    public async updateUser(user: ProfileUpdateRequest): Promise<UserProfile> {
        return await this.api
            .patch(`/profile/update`, user, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((it) => it.data)
    }

    public async getProfile(): Promise<UserProfile> {
        return await this.api.get('/profile/get').then(it => it.data)
    }

    public async updatePassword(old: string, newPassword: string): Promise<void> {
        const body: any = {
            oldPassword: old,
            newPassword,
        }

        return await this.api
            .patch(`/profile/update/password`, body)
            .then((it) => it.data)
    }
}
