import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemButton from '@mui/material/ListItemButton'
import { fToNow } from 'src/utils/format-time'
import Label from 'src/components/label'
import React from 'react'
import Typography from '@mui/material/Typography'
import Iconify from 'src/components/iconify'
import { NotificationItem } from '../../service/types/common'
import { useNavigate } from 'react-router-dom'

type NotificationItemProps = {
    notification: NotificationItem
}

export default function NotificationItemView({ notification }: NotificationItemProps) {
    const navigate = useNavigate()

    const handleClick = () => {
        navigate(`/orders/show/${notification.id}`, { state: { id: notification.id } })
    }

    const renderAvatar = (
        <ListItemAvatar>
            <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                    width: 40,
                    height: 40,
                    borderRadius: '50%',
                    bgcolor: 'background.neutral',
                }}
            >
                <Iconify icon={'ion:notifcations'} sx={{ width: 24, height: 24 }} />
            </Stack>
        </ListItemAvatar>
    )

    const renderText = (
        <ListItemText
            disableTypography
            primary={
                <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ typography: 'subtitle1', color: 'text.secondary' }}
                >
                    {reader(notification.subject)}

                </Stack>
            }
            secondary={
                <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ typography: 'caption', fontWeight: 600, color: 'text.disabled' }}
                    divider={
                        <Box
                            sx={{
                                width: 2,
                                height: 2,
                                bgcolor: 'currentColor',
                                mx: 0.5,
                                borderRadius: '50%',
                            }}
                        />
                    }
                >
                    {fToNow(notification.date)}
                </Stack>
            }
        />
    )

    const renderUnReadBadge = notification.unread && (
        <Box
            sx={{
                top: 26,
                width: 8,
                height: 8,
                right: 20,
                borderRadius: '50%',
                bgcolor: 'info.main',
                position: 'absolute',
            }}
        />
    )

    return (
        <ListItemButton
            disableRipple
            onClick={handleClick}
            sx={{
                p: 2.5,
                alignItems: 'flex-start',
                borderBottom: (theme) => `dashed 2px ${theme.palette.divider}`,
            }}
        >
            {renderUnReadBadge}

            {renderAvatar}

            <Stack sx={{ flexGrow: 1 }}>
                {renderText}
                <Stack alignItems="flex-start">
                    <Box
                        sx={{
                            p: 1.5,
                            my: 1.5,
                            borderRadius: 1.5,
                            color: (theme) => theme.palette.grey[theme.palette.mode === 'light' ? 700 : 300],
                            backgroundColor: (theme) => theme.palette.mode === 'light' ? '#FFF6DD' : 'background.neutral',
                        }}
                    >
                        <Typography variant={'body2'} sx={{ fontWeight: 500 }}>
                            <div dangerouslySetInnerHTML={{ __html: notification.message }} />
                        </Typography>
                    </Box>
                </Stack>
                {notification.badge && <Stack direction="row" spacing={0.75} flexWrap="wrap" sx={{ mt: 1.5 }}>
                    <Label variant="soft" color={notification.badgeSeverity}>
                        {notification.badgeMessage}
                    </Label>
                </Stack>
                }
            </Stack>
        </ListItemButton>
    )
}

function reader(data: string) {
    return (
        <Box
            dangerouslySetInnerHTML={{ __html: data }}
            sx={{
                mb: 0.5,
                '& p': { typography: 'body2', m: 0 },
                '& a': { color: 'inherit', textDecoration: 'none' },
                '& strong': { typography: 'subtitle2' },
            }}
        />
    )
}
