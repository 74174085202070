import { alpha } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import { fCurrency, fNumber } from 'src/utils/format-number'
import Iconify from 'src/components/iconify'
import React from 'react'

type Props = {
    icon: string;
    title: string;
    total?: number;
    price?: number;
    color?: string;
};

export default function OrderReportAnalytic({ title, total, icon, color, price }: Props) {
    return (
        <Stack
            spacing={2.5}
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ width: 1, minWidth: 200 }}
        >
            <Stack alignItems="center" justifyContent="center" sx={{ position: 'relative' }}>
                <Iconify icon={icon} width={32} sx={{ color, position: 'absolute' }} />

                <CircularProgress
                    variant="determinate"
                    value={100}
                    size={56}
                    thickness={2}
                    sx={{ color, opacity: 0.48 }}
                />

                <CircularProgress
                    variant="determinate"
                    value={100}
                    size={56}
                    thickness={3}
                    sx={{
                        top: 0,
                        left: 0,
                        opacity: 0.48,
                        position: 'absolute',
                        color: (theme) => alpha(theme.palette.grey[500], 0.16),
                    }}
                />
            </Stack>

            <Stack spacing={0.5}>
                <Typography variant="subtitle2">{title}</Typography>

                {total && <Typography variant="subtitle2">
                    {fNumber(total)}
                </Typography>
                }

                {price && <Typography variant="subtitle2">{fCurrency(price)}</Typography>}
            </Stack>
        </Stack>
    )
}
