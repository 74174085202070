import React from 'react'
import { ArrayInput, NumberInput, SimpleFormIterator } from 'react-admin'

export default function TownDeliveryFeeInput() {
    return <ArrayInput name={'tiers'} source={'tiers'} helperText={'Bedava teslimat icin 0 giribilirsiniz'}>
        <SimpleFormIterator inline>
            <NumberInput source="startAmount" label={'resources.towns.fields.startAmount'}/>
            <NumberInput source="endAmount" label={'resources.towns.fields.endAmount'}  />
            <NumberInput source="fee" label={'resources.towns.fields.fee'}/>
        </SimpleFormIterator>
    </ArrayInput>
}