import { AxiosInstance } from 'axios'
import { IncomeReport, OrderCountStatistics, Period, SalesReport } from './types/report'
import { useRestService } from './base-service'

export const useSaleReportService = () => {
    const api = useRestService()
    return new SaleReportService(api)
}

class SaleReportService {

    private readonly api: AxiosInstance

    constructor(api: AxiosInstance) {
        this.api = api
    }

    async getTodayReport(): Promise<SalesReport> {
        return await this.api.get('/report/sales/today').then(it => it.data)
    }

    async getReport(start: number, end: number): Promise<SalesReport> {
        return await this.api.get(`/report/sales/get?start=${start}&end=${end}`).then(it => it.data)
    }

    async getOrderStatistics(start: number, end: number, period: Period): Promise<OrderCountStatistics[]> {
        return await this.api.get(`/report/sales/count?start=${start}&end=${end}&period=${period}`).then(it => it.data)
    }

    async getIncomeReport(start: number, end: number, period: Period): Promise<IncomeReport> {
        return await this.api.get(`/report/sales/income?start=${start}&end=${end}&period=${period}`).then(it => it.data)
    }
}