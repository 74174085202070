import { RxStomp } from '@stomp/rx-stomp'
import { Subject, Subscription } from 'rxjs'
import { LoggedUser } from './types/user'
import { useEffect } from 'react'
import { ApiAuthProvider } from './api-auth-provider'
import { Order, OrderStatus } from './types/order'
import { globalConfig } from '../global-config'

type Props = {
    onNewOrder: (order: Order) => void
    onConfirmOrder?: (order: Order) => void
    onCanceledOrder?: (order: Order) => void
    onDeliveredOrder?: (order: Order) => void
}
export const useLiveOrderService = ({ onNewOrder, onConfirmOrder, onCanceledOrder, onDeliveredOrder }: Props) => {
    useEffect(() => {
        const session = ApiAuthProvider.INSTANCE.getSession()
        const subscription = LiveOrderService.INSTANCE.onNextOrder.subscribe((order) => {
            if (order.status === OrderStatus.PENDING) {
                onNewOrder(order)
            } else if (order.status === OrderStatus.CONFIRMED) {
                onConfirmOrder?.(order)
            } else if (order.status === OrderStatus.CANCELLED) {
                onCanceledOrder?.(order)
            } else if (order.status === OrderStatus.DELIVERED) {
                onDeliveredOrder?.(order)
            }
        })
        if (session) {
            LiveOrderService.INSTANCE.connect(session)
        }
        return () => {
            subscription.unsubscribe()
        }
    }, [])
    return LiveOrderService.INSTANCE

}

export class LiveOrderService {
    private readonly client = new RxStomp()
    public static readonly INSTANCE = new LiveOrderService()
    private subscription: Subscription | undefined
    private connectionSubscription: Subscription | undefined
    onNextOrder = new Subject<Order>()

    public connect(session: LoggedUser, force: boolean = false) {
        if (force) {
            if (this.client.connected() || this.client.active) {
                this.disconnect()
            }
        } else if (this.client.connected() || this.client.active) {
            return
        }
        const headers = {
            Authorization: `Bearer ${session.token}`,
        }
        const server = globalConfig.api.base.replace('http://', 'ws://').replace('https://', 'wss://')
        this.client.configure({
            connectHeaders: headers,
            brokerURL: `${server}/ws`,
        })
        this.connectionSubscription = this.client.connectionState$
            .subscribe((state) => {
                console.log('Connection state', state)
            })
        this.client.stompErrors$.subscribe((error) => {
            console.log('Stomp error', error)
        })
        this.client.activate()

        this.subscription = this.client
            .watch({
                destination: `/admin/orders/${session.marketId}`,
                subHeaders: () => headers,
                unsubHeaders: () => headers,
            })
            .subscribe((message) => {
                const body = message.body
                try {
                    const order = JSON.parse(body)
                    this.onNextOrder.next(order)
                } catch (e) {
                    console.error('Error parsing order', e)
                }
            })
    }

    public disconnect() {
        try {
            if (this.subscription) {
                this.subscription.unsubscribe()
            }
            if (this.connectionSubscription) {
                this.connectionSubscription.unsubscribe()
            }
            void this.client.deactivate({ force: true })
        } catch (e) {
            // ignore
        }
    }
}