import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import LoadingButton from '@mui/lab/LoadingButton'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import { useBoolean } from 'src/hooks/use-boolean'
import Iconify from 'src/components/iconify'
import FormProvider, { RHFTextField } from 'src/components/hook-form'
import React, { useState } from 'react'
import { useAccountService } from '../../service/account-service'
import Grid from '@mui/material/Grid'
import Alert from '@mui/material/Alert'
import { Message } from '../../service/types/common'

export default function AccountChangePassword() {
    const accountService = useAccountService()
    const password = useBoolean()
    const [message, setMessage] = useState<Message | null>(null)
    const ChangePassWordSchema = Yup.object().shape({
        oldPassword: Yup.string().required('Eski şifrenizi giriniz'),
        newPassword: Yup.string()
            .required('Yeni şifrenizi giriniz')
            .matches(/^(?=.*[0-9])(?=.*[!@#$%^&*])(?=\S+$).{8,}$/, 'Şifre en az 8 karakter ve en az bir rakam ve bir özel karakter içermelidir.'),
        confirmNewPassword: Yup.string().oneOf(
            [Yup.ref('newPassword')], 'Şifreler uyuşmuyor',
        ),
    })

    const defaultValues = {
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    }

    const methods = useForm({
        resolver: yupResolver(ChangePassWordSchema),
        defaultValues,
    })

    const {
        reset,
        handleSubmit,
        formState: { isSubmitting },
    } = methods

    const onSubmit = handleSubmit(async (data) => {
        try {
            await accountService.updatePassword(data.oldPassword, data.newPassword)
            setMessage({ severity: 'success', text: 'Şifreniz değiştirildi. Lütfen tekrar oturum açın' })
            reset()
        } catch (error) {
            setMessage({ severity: 'error', text: error.message })
        }
    })

    return (
        <FormProvider methods={methods} onSubmit={onSubmit}>
            <Grid container spacing={0}>
                <Grid xs={12} md={6}>
                    <Stack component={Card} spacing={3} sx={{ p: 4, pt: 6 }}>
                        <RHFTextField
                            name="oldPassword"
                            type={password.value ? 'text' : 'password'}
                            disabled={isSubmitting}
                            label={'Eski Şifre'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={password.onToggle} edge="end">
                                            <Iconify
                                                icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <RHFTextField
                            name="newPassword"
                            disabled={isSubmitting}
                            label={'Yeni Şifre'}
                            type={password.value ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={password.onToggle} edge="end">
                                            <Iconify
                                                icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <RHFTextField
                            name="confirmNewPassword"
                            disabled={isSubmitting}
                            type={password.value ? 'text' : 'password'}
                            label={'Yeni Şifre Tekrar'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={password.onToggle} edge="end">
                                            <Iconify
                                                icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {message && <Alert severity={message.severity}>{message.text}</Alert>}
                        <LoadingButton type="submit" variant="contained" color={'primary'} loading={isSubmitting}
                                       sx={{ ml: 'auto' }}>
                            {isSubmitting ? 'Gönderiliyor' : 'Şifreyi Değiştir'}
                        </LoadingButton>
                    </Stack>
                </Grid>
            </Grid>
        </FormProvider>
    )
}
