import { ApiAuthProvider } from '../service/api-auth-provider'

export const useUserContext = () => {
    const user = ApiAuthProvider.INSTANCE.getSession()
    if (user) {
        return user
    }
}

export const useIsCompany = () => {
    const user = useUserContext()
    return user?.authority === 'ROLE_COMPANY'
}