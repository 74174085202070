import React from 'react'
import { Datagrid, FieldProps, Filter, List, TextField, TextInput, useRecordContext } from 'react-admin'
import { PaymentStatus } from '../../../service/types/order'
import Icon from '@mui/icons-material/PaymentsRounded'
import Label from '../../../components/label'
import { fCurrency } from '../../../utils/format-number'
import { OrderStatusLabel } from '../order-row'
import { TimeField } from '../../../common/TimeField'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import PaymentCardIcon from '../../../common/payment-card-icon'
import { paymentStatusChoices } from './payment-list+'
import { CustomSelectInput } from '../../../components/ra-admin/select-input-override'
import { FormDateRangeInput } from '../../../common/date/date-range-input'

export const PaymentIcon = Icon

const Filters = (props: any) => {
    return <Filter {...props}>
        <TextInput name={'paymentId'} source={'paymentId'} alwaysOn />
        <CustomSelectInput source="status" name={'status'} choices={paymentStatusChoices} alwaysOn />
        <FormDateRangeInput source={'time'} required={false} name={'time'} alwaysOn label={'Between'} sx={{
            width: 300,
        }} />
    </Filter>
}

export default function PaymentListView() {
    return (
        <List exporter={false} storeKey={false} filters={<Filters />}>
            <Datagrid bulkActionButtons={false}>
                <TextField source="paymentId" />
                <AmountField source="amount" />
                <TextField source="currency" />
                <CardNumberField source="cardNumber" />
                <InstallmentField source="installments" />
                <PaymentStatusField source="status" />
                <OrderStatusField source="order.status" sortable={false} />
                <TimeField source="refundTime" />
                <TimeField source="paymentTime" />
            </Datagrid>
        </List>
    )
}

const PaymentStatusField = ({ source }: FieldProps) => {
    const item = useRecordContext()
    if (!item) {
        return <></>
    }
    const status = source ? item[source] : item.status
    return <Label variant={'soft'}
                  color={status === PaymentStatus.SUCCESS ? 'success' : 'error'}>
        {status === PaymentStatus.SUCCESS ? 'Ödenmiş' : 'Iade Edilmiş'}
    </Label>
}

const AmountField = ({ source }: FieldProps) => {
    const record = useRecordContext()
    if (!record) {
        return <></>
    }
    const amount = source ? source.split('.').reduce((acc, key) => acc[key], record) : record.amount
    return <span>{fCurrency(amount)}</span>
}

const OrderStatusField = ({ source }: FieldProps) => {
    const record = useRecordContext()
    if (!record) {
        return <></>
    }
    const status = source ? source.split('.').reduce((acc, key) => acc[key], record) : record['order.status']
    return <OrderStatusLabel status={status} />
}

const CardNumberField = ({ source }: FieldProps) => {
    const record = useRecordContext()
    if (!record) {
        return <></>
    }
    const cardNumber = source ? source.split('.').reduce((acc, key) => acc[key], record) : record.cardNumber
    return <Stack direction={'row'} spacing={1} alignItems={'center'}>
        <PaymentCardIcon brand={record.cardBrand} width={24} height={24} />
        <Typography variant={'body2'}>
            {cardNumber}
        </Typography>
    </Stack>
}

const InstallmentField = ({ source }: FieldProps) => {
    const record = useRecordContext()
    if (!record) {
        return <></>
    }
    const installment = source ? source.split('.').reduce((acc, key) => acc[key], record) : record.installment
    const text = installment === 1 ? 'Tek Çekim' : `${installment} Taksit`
    return <span>{text}</span>
}