import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import CardHeader from '@mui/material/CardHeader'
import { fCurrencyTl } from 'src/utils/format-number'
import Scrollbar from 'src/components/scrollbar'
import { OrderItem } from '../../../service/types/order'
import React from 'react'
import { OrderItemsTable } from './order-items-table'

type Props = {
    shipping: number;
    subTotal: number;
    totalAmount: number;
    items: OrderItem[];
};

export default function OrderDetailsItems({
                                              items,
                                              shipping,
                                              subTotal,
                                              totalAmount,
                                          }: Props) {
    const renderTotal = (
        <Stack
            spacing={2}
            alignItems="flex-end"
            sx={{ my: 3, textAlign: 'right', typography: 'body2' }}
        >
            <Stack direction="row">
                <Box sx={{ color: 'text.secondary' }}>Toplam</Box>
                <Box sx={{ width: 160, typography: 'subtitle2' }}>{fCurrencyTl(subTotal) || '-'}</Box>
            </Stack>

            <Stack direction="row">
                <Box sx={{ color: 'text.secondary' }}>Teslim Ücreti</Box>
                <Box
                    sx={{
                        width: 160,
                        ...(shipping && { color: 'error.main' }),
                    }}
                >
                    {shipping ? `+ ${fCurrencyTl(shipping)}` : '-'}
                </Box>
            </Stack>

            <Stack direction="row" sx={{ typography: 'subtitle1' }}>
                <Box>Total</Box>
                <Box sx={{ width: 160 }}>{fCurrencyTl(totalAmount) || '-'}</Box>
            </Stack>
        </Stack>
    )

    return (
        <Card>
            <CardHeader title="Ürünler" />

            <Stack
                sx={{
                    px: 3,
                }}
            >
                <Scrollbar>
                    <OrderItemsTable items={items} />
                </Scrollbar>

                {renderTotal}
            </Stack>
        </Card>
    )
}
