import { BaseCrudService } from '../base-crud-service'
import { Product, ProductInsertRequest } from '../types/product'
import { AxiosInstance } from 'axios'
import { useRestService } from '../base-service'

export const useProductService = () => {
    const api = useRestService()
    return new ProductService(api)
}

class ProductService extends BaseCrudService<Product, ProductInsertRequest> {
    constructor(api: AxiosInstance) {
        super(api, '/products')
    }

    public async search(query: string): Promise<Product[]> {
        return await this.api.get<Product[]>(`${this.resource}/search`, {
            params: { query },
        }).then((response) => response.data)
    }
}