import Stack from '@mui/material/Stack'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import React, { useEffect } from 'react'
import { endOfDay, startOfDay, startOfMonth, startOfWeek, startOfYear } from 'date-fns'
import { PeriodInputView } from '../common/period-input-view'
import { Period } from '../service/types/report'

export type DateInput = {
    title: string
    start: Date
    end: Date
    id: string
}

export const Today: DateInput = {
    title: 'Bugün',
    start: startOfDay(new Date()),
    end: endOfDay(new Date()),
    id: 'today',
}

type Prop = {
    onChange: (date: DateInput) => void
    period: Period
    onPeriodChange: (period: Period) => void
}
export default function ChartCommonInput({ onChange, onPeriodChange, period }: Prop) {
    const [date, setDate] = React.useState<string>('today')
    const [values, setValues] = React.useState<DateInput[]>()


    useEffect(() => {
        const selected = values?.find((value) => value.id === date)
        if (selected) {
            onChange(selected)
        }
    }, [date])

    useEffect(() => {
        // fill date input with today, yesterday and then week, month, year
        const today = new Date()
        const yesterday = new Date()
        yesterday.setDate(today.getDate() - 1)
        setValues([
            Today,
            { title: 'Dün', start: startOfDay(yesterday), end: endOfDay(yesterday), id: 'yesterday' },
            { title: 'Bu Hafta', start: startOfWeek(today), end: endOfDay(today), id: 'week' },
            { title: 'Bu Ay', start: startOfMonth(today), end: endOfDay(today), id: 'month' },
            { title: 'Bu Yıl', start: startOfYear(today), end: endOfDay(today), id: 'year' },
            {
                title: 'Son 7 Gün',
                start: startOfDay(new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000)),
                end: endOfDay(today),
                id: 'last7',
            },
            {
                title: 'Son 30 Gün',
                start: startOfDay(new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000)),
                end: endOfDay(today),
                id: 'last30',
            },
            {
                title: 'Son 90 Gün',
                start: startOfDay(new Date(today.getTime() - 90 * 24 * 60 * 60 * 1000)),
                end: endOfDay(today),
                id: 'last90',
            },
            {
                title: 'Son 180 Gün',
                start: startOfDay(new Date(today.getTime() - 180 * 24 * 60 * 60 * 1000)),
                end: endOfDay(today),
                id: 'last180',
            },
        ])
    }, [])

    return (
        <Stack spacing={1} direction={'row'} sx={{ width: '100%', pr: 2, justifyContent: 'end' }}>
            <PeriodInputView period={period} onPeriodChange={onPeriodChange} />
            <FormControl size={'small'} sx={{ minWidth: 200 }}>
                <InputLabel size={'small'} variant={'filled'} htmlFor="demo-controlled-open-select">
                    Zaman Aralığını Seçin
                </InputLabel>
                <Select
                    value={date}
                    size={'small'}
                    variant={'filled'}
                    label={'Zaman Aralığını Seçin'}
                    onChange={(event) => {
                        setDate(event.target.value)
                    }}
                    inputProps={{
                        name: 'period',
                        id: 'demo-controlled-open-select',
                    }}
                >
                    {values?.map((value) => (
                        <MenuItem key={value.id} value={value.id}>
                            {value.title}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Stack>
    )
}
