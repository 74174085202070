import React, { useCallback, useEffect } from 'react'
import { OrderCountStatistics, Period } from '../service/types/report'
import { useSaleReportService } from '../service/sale-report-service'
import { useNotify } from 'react-admin'
import CountStatisticsChart from './count-statistics-chart'

type Props = {
    start: Date;
    end: Date;
}
export default function OrderCountChart({ start, end }: Props) {
    const [period, setPeriod] = React.useState<Period>(Period.DAY)
    const [statistics, setStatistics] = React.useState<OrderCountStatistics[]>()
    const service = useSaleReportService()
    const notify = useNotify()

    const fetchStatistics = useCallback(async (start: Date, end: Date, period: Period) => {
        await service.getOrderStatistics(start.getTime(), end.getTime(), period)
            .then(setStatistics)
            .catch(() => {
                notify('Failed to fetch statistics', { type: 'error' })
            })
    }, [])

    useEffect(() => {
        void fetchStatistics(start, end, period)
    }, [period, start, end])

    if (!statistics) {
        return <></>
    }

    return <CountStatisticsChart
        period={period}
        onChartPeriodChange={setPeriod}
        title="Sipariş Sayıları"
        chart={{
            categories: statistics.map((i) => i.title),
            series: [
                {
                    name: 'Tamamlanmış Siparişler',
                    data: statistics.map((i) => i.completed),
                },
                {
                    name: 'İptal Edilen Siparişler',
                    data: statistics.map((i) => i.cancelled),
                },
                {
                    name: 'Onaylanmış Siparişler',
                    data: statistics.map((i) => i.confirmed),
                },
            ],
        }} />
}
