import { Product } from '../../../../service/types/product'
import React, { useState } from 'react'
import { useBoolean } from '../../../../hooks/use-boolean'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import { useProductService } from '../../../../service/crud/product-service'
import TextField from '@mui/material/TextField'
import { useNotify } from 'react-admin'
import { CustomDialogTitle } from '../../../../components/custom-dialog/title/custom-dialog-title'
import Stack from '@mui/material/Stack'
import { LoadingButton } from '@mui/lab'
import Scrollbar from '../../../../components/scrollbar'
import { SearchedProductListView } from './searched-product-list-view'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'

type Props = {
    open: boolean;
    onClose: () => void;
    onAdd: (product: Product) => void;
}

export const AddProductDialog = ({ open, onClose, onAdd }: Props) => {
    const [search, setSearch] = useState('')
    const loading = useBoolean(false)
    const [products, setProducts] = useState<Product[]>([])
    const productService = useProductService()
    const notify = useNotify()

    const searchProduct = async () => {
        loading.onTrue()
        await productService.search(search)
            .then(products => {
                if (products.length === 0) {
                    notify('Ürün bulunamadı.', { type: 'error' })
                    return
                }
                setProducts(products)
            })
            .catch(() => {
                notify('Ürün getirilirken bir hata oluştu lütfen tekrar deneyin.', { type: 'error' })
            })
            .finally(loading.onFalse)
    }

    return <Dialog open={open} onClose={onClose} fullWidth sx={{ minHeight: 500 }}>
        <CustomDialogTitle onClose={onClose} title={'Ürün Ekle'} />
        <DialogContent sx={{ p: 2, height: 400 }}>
            <Stack spacing={2}>
                <TextField
                    label={'Ürün Ara'}
                    value={search}
                    size={'small'}
                    helperText={'Ürün adı veya barkodunu girin'}
                    onChange={(e) => {
                        setSearch(e.target.value)
                    }}
                    fullWidth
                />
                <Box>
                    <LoadingButton
                        variant={'contained'}
                        color={'primary'}
                        loading={loading.value}
                        onClick={searchProduct}>
                        Ürün Ara
                    </LoadingButton>
                </Box>
                {loading.value &&
                    <Stack alignItems={'center'}>
                        <CircularProgress size={24} />
                    </Stack>}
                {!loading.value && <Scrollbar sx={{ maxHeight: 200 }}>
                    <SearchedProductListView products={products} onItemSelected={onAdd} />
                </Scrollbar>
                }
            </Stack>
        </DialogContent>
    </Dialog>
}