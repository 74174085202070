import React from 'react'
import { List, useListContext, useRefresh } from 'react-admin'
import OrderTable from './order-table'
import Icon from '@mui/icons-material/ShoppingBasket'
import Card from '@mui/material/Card'

export const OrderIcon = Icon

export const OrdersList = () => {
    return <List exporter={false} pagination={false}>
        <OrdersGird />
    </List>
}

export const OrdersGird = () => {
    const { data, page, perPage, setPage, setPerPage, total, setSort, sort } = useListContext()
    const refresh = useRefresh()
    if (data == null) {
        return <></>
    }
    return <Card sx={{ maxHeight: '100%' }}>
        <OrderTable orders={data}
                    refresh={refresh}
                    page={page}
                    total={total}
                    rowsPerPage={perPage}
                    onSort={setSort}
                    order={sort.order}
                    orderBy={sort.field}
                    onRowsPerPageChange={setPerPage}
                    onChangePage={setPage} />
    </Card>
}