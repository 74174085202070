import { PaymentType } from './payment-type'

export type OrderAddress = {
    id: string,
    address: string,
}

export type OrderHistory = {
    orderTime?: number;
    completionTime?: number;
    timeline: Array<{
        title: string;
        time: number;
    }>;
};

export type OrderItemProduct = {
    id: string,
    image: string,
    name: string,
}

export type OrderItem = {
    product: OrderItemProduct,
    quantity: number,
    price: number,
    discount?: number
}

export enum OrderStatus {
    PENDING = 'PENDING',
    CONFIRMED = 'CONFIRMED',
    DELIVERED = 'DELIVERED',
    CANCELLED = 'CANCELLED',
    ONWAY = 'ONWAY'
}

export type OrderCustomer = {
    id: string,
    name: string,
    phone: string,
}

export type OrderDriver = {
    id: string,
    name: string,
    phone: string,
}
export type PaymentInfo = {
    cardType: string,
    cardLastDigit: string,
    cardBrand: string,
    id: string,
    time: number,
}

export type Order = {
    id: string,
    items: OrderItem[],
    customer: OrderCustomer,
    address: OrderAddress,
    status: OrderStatus,
    total: number,
    customerNote?: string,
    suitableTime?: number,
    driver?: OrderDriver,
    pendingTime: number,
    confirmTime?: number,
    deliverTime?: number,
    cancelTime?: number,
    cancelReason?: string,
    finalPrice: number,
    deliveryFee?: number,
    paymentType: PaymentType,
    paymentInfo?: PaymentInfo,
}

// take PaymentReferenceRest and make typescript

export type PaymentOrderReference = {
    id: string
    status: OrderStatus
}

export enum PaymentStatus {
    SUCCESS = 'SUCCESS',
    REFUNDED = 'REFUNDED',
}

export type PaymentReference = {
    id: string,
    amount: number,
    currency: string,
    status: PaymentStatus,
    paymentTime: number,
    refundTime?: number,
    order: PaymentOrderReference,
    paymentId: string,
    cardType: string,
    cardNumber: string,
    cardBrand: string,
}

export type OrderUpdateRequest = {
    orderId: string,
    items: Array<{ id: string, quantity: number }>,
    discount?: number,
    deliveryFee?: number,
}