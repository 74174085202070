import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, InputLabel, MenuItem, Select } from '@mui/material'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import CircularProgress from '@mui/material/CircularProgress'
import FormControl from '@mui/material/FormControl'
import { Driver } from '../../service/types/user'
import { useOrderService } from '../../service/order-service'
import Stack from '@mui/material/Stack'
import { useNotify } from 'react-admin'
import { fDateTime } from '../../utils/format-time'
import Alert from '@mui/material/Alert'
import moment from 'moment'

type Props = {
    id: string,
    open: boolean,
    deliveryTime?: number,
    onClose: (success: boolean) => void
}

export function OrderConfirmDialog({ id, deliveryTime, open, onClose }: Props) {
    const [drivers, setDrivers] = useState<Driver[]>([])
    const [loading, setLoading] = useState(false)
    const [driver, setDriver] = useState('')
    const orderService = useOrderService()
    const notify = useNotify()
    useEffect(() => {
        setLoading(true)
        const fetchDrivers = async () => {
            await orderService.getDrivers()
                .then((res) => {
                    setDrivers(res)
                })
                .catch(() => {
                    notify('Sürücüler yüklenirken hata oluştu', { type: 'error' })
                })
                .finally(() => {
                    setLoading(false)
                })
        }
        void fetchDrivers()
    }, [])

    function handleConfirm() {
        setLoading(true)
        orderService.assignDriver(id, driver)
            .then(() => {
                notify('Siparis onaylandi', { type: 'success' })
                onClose(true)
            })
            .catch((e) => {
                notify('Siparis onaylanamadi', { type: 'error' })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const onDriverSelect = (event: any) => {
        setDriver(event.target.value)
    }

    return (
        <Dialog open={open} onClose={() => {
            onClose(false)
        }}>
            <DialogTitle>{`Siparış Onayla #${id}`}</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    {deliveryTime && moment(deliveryTime).isAfter(moment()) &&
                        <Alert severity={'error'} variant={'filled'}>Bu siparış ileri tarihi
                            verilmiştir.<br /><b>Müşterinin istediği teslimat tarihi: {fDateTime(deliveryTime)}</b>. <br />
                            Gerçekten şimdi onaylamak istiyor musunuz?</Alert>}
                    <DialogContentText>Lütfen onaylanmak için sürücü seçin</DialogContentText>
                    <FormControl fullWidth>
                        <InputLabel id="driver-select-label">Sürücü Seç</InputLabel>
                        <Select id="driver-select"
                                size={'small'}
                                value={driver} label="Sürücü" onChange={onDriverSelect}>
                            {drivers.map((d) => (
                                <MenuItem value={d.id} key={d.id}>
                                    {d.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={loading}
                    variant={'text'}
                    color={'primary'}
                    onClick={() => {
                        onClose(false)
                    }}
                >
                    Iptal
                </Button>
                <Button
                    disabled={loading || driver === ''}
                    variant={'text'}
                    color={'secondary'}
                    onClick={handleConfirm}
                >
                    {loading && <CircularProgress size={25} thickness={2} />}
                    Onayla
                </Button>
            </DialogActions>
        </Dialog>
    )
}
