import React from 'react'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { OrderItem } from '../../../service/types/order'
import { fCurrencyTl } from '../../../utils/format-number'

type Props = {
    item: OrderItem
}

export const OrderItemRowHead = [
    { id: 'product', label: 'Ürün' },
    { id: 'quantity', label: 'Adet' },
    { id: 'price', label: 'Fiyat' },
    { id: 'discount', label: 'İndirimli Fiyat' },
    { id: 'total', label: 'Toplam' },
]

export const OrderItemRow = ({ item }: Props) => {
    const usedPrice = item.discount ?? item.price
    return <TableRow>
        <TableCell>{item.product.name}</TableCell>
        <TableCell>{item.quantity}</TableCell>
        <TableCell>{fCurrencyTl(item.price)}</TableCell>
        <TableCell>{fCurrencyTl(item.discount ?? 0)}</TableCell>
        <TableCell>{fCurrencyTl(usedPrice * item.quantity)}</TableCell>
    </TableRow>
}