import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import LoadingButton from '@mui/lab/LoadingButton'
import Stack from '@mui/material/Stack'
import FormProvider from 'src/components/hook-form'
import React, { useState } from 'react'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuthService } from '../service/auth-service'
import RHFCode from '../components/hook-form/rhf-code'
import EmailInboxIcon from '../images/email-inbox-icon'

type Props = {
    token: string
}

export default function LoginCodeView({ token }: Props) {
    const [error, setError] = useState('')
    const navigate = useNavigate()
    const location = useLocation()
    const userService = useAuthService()
    const NewPasswordSchema = Yup.object().shape({
        code: Yup.string().min(4, '').required(''),
    })

    const defaultValues = {
        code: '',
    }

    const methods = useForm({
        mode: 'onChange',
        resolver: yupResolver(NewPasswordSchema),
        defaultValues,
    })

    const {
        handleSubmit,
        formState: { isSubmitting },
    } = methods

    const onSubmit = handleSubmit(async (data) => {
        try {
            setError('')
            await userService.verify(token, data.code)
            navigate(location.state ? location.state.nextPathname : '/')
        } catch (error) {
            setError(error.message)
        }
    })

    const renderForm = (
        <Stack spacing={3} alignItems="center">
            <RHFCode name="code" />

            <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
            >
                Onayla
            </LoadingButton>

        </Stack>
    )

    const renderHead = (
        <>
            <EmailInboxIcon sx={{ height: 96 }} />
            <Stack spacing={1} sx={{ my: 5 }}>
                <Typography variant="h3">{'Lütfen Girişi Onaylayın'}</Typography>

                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {'Gelen kutunuzdaki onay kodunu girerek hesabınızı onaylayabilirsiniz.'}
                </Typography>

                <Alert sx={{ mt: 2 }} severity="warning">
                    Eğer onay kodunuzu gelen kutunuzda bulamıyorsanız, lütfen spam ya da gereksiz e-postalar klasörünüzü
                    kontrol ediniz.
                </Alert>
                {error && <Alert severity="error">{error}</Alert>}
            </Stack>
        </>
    )

    return (
        <FormProvider methods={methods} onSubmit={onSubmit}>
            {renderHead}

            {renderForm}
        </FormProvider>
    )
}
