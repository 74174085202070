import { m } from 'framer-motion'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Iconify from 'src/components/iconify'
import { varHover } from 'src/components/animate'
import React, { useCallback } from 'react'
import { useRefresh } from 'react-admin'
import { useLoading } from 'ra-core'

export default function RefreshButton() {
    const refresh = useRefresh()
    const loading = useLoading()
    const handleClick = useCallback((event: any) => {
            event.preventDefault()
            refresh()
        },
        [refresh],
    )

    return (
        <Box
            component={m.div}
            animate={{
                rotate: [0, loading ? 360 : 0],
            }}
            transition={{
                duration: 1,
                ease: 'linear',
                repeat: Infinity,
            }}
        >
            <IconButton
                component={m.button}
                whileTap="tap"
                whileHover="hover"
                variants={varHover(1.05)}
                aria-label="settings"
                onClick={handleClick}
                sx={{
                    width: 40,
                    height: 40,
                }}
            >
                <Iconify icon="solar:refresh-circle-line-duotone" width={40} />
            </IconButton>
        </Box>
    )
}
