import Box from '@mui/material/Box'
import React, { useEffect } from 'react'
import { useBoolean } from '../../hooks/use-boolean'
import { Chip, CircularProgress, DialogContentText } from '@mui/material'
import Switch from '@mui/material/Switch'
import { ConfirmDialog } from '../../components/custom-dialog'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { useAppConfigService } from '../../service/app-config-service'
import { useNotify } from 'react-admin'
import { ApiAuthProvider } from '../../service/api-auth-provider'

export default function SystemStatusButton() {
    const loading = useBoolean()
    const active = useBoolean()
    const confirm = useBoolean()
    const notify = useNotify()
    const service = useAppConfigService()
    useEffect(() => {
        loading.onTrue()
        void load()
    }, [])

    const load = async () => {
        const auth = ApiAuthProvider.INSTANCE.getSession()
        if (!auth) {
            return
        }
        await service.getSystemStatus()
            .then(enabled => {
                active.setValue(enabled)
            })
            .catch((e) => {
                // ignore
            })
            .finally(loading.onFalse)
    }

    const updateStatus = async () => {
        loading.onTrue()
        confirm.onFalse()
        await service.updateSystemStatus(!active.value)
            .then(value => {
                active.setValue(value)
                notify('Sistem durumu başarıyla değiştirildi', { type: 'success' })
            })
            .catch(() => {
                notify('Sistem durumu değiştirilemedi', { type: 'error' })
            })
            .finally(loading.onFalse)
    }

    return (
        <Box>
            <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={1}>
                {loading.value && <CircularProgress size={24} color={'primary'} />}
                <Chip
                    clickable={true}
                    sx={{
                        '& .MuiChip-icon': {
                            marginLeft: '-4px',
                        },
                    }}
                    disabled={loading.value}
                    icon={<Switch sx={{ mr: 10 }} checked={active.value} />}
                    onClick={confirm.onTrue}
                    label={active.value ? 'Aktif' : 'Kapalı'}
                    variant={'outlined'}
                />
            </Stack>
            <ConfirmDialog
                open={confirm.value}
                title={'Sistem Durumunu Değiştirmeyi Onaylıyor musunuz?'}
                action={
                    <Button color={'error'} variant={'outlined'} onClick={updateStatus}>
                        Evet
                    </Button>
                }
                onClose={confirm.onFalse}
                content={
                    <DialogContentText>
                        Sistemin etkin durumunu gerçekten değiştirmek istiyor musunuz? Sistemi kapatırsanız kullanıcılar
                        sipariş veremeyeceklerdir.
                    </DialogContentText>
                }
            />
        </Box>
    )
}
