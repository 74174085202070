import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import CardHeader from '@mui/material/CardHeader'
import Typography from '@mui/material/Typography'
import React from 'react'
import { OrderAddress, OrderCustomer, OrderDriver, PaymentInfo } from '../../../service/types/order'
import PaymentCardIcon from '../../../common/payment-card-icon'
import { getPaymentLabel } from '../../../utils/period+'
import { SuitabilityTimeLabel } from '../order-row'
import { PaymentType } from '../../../service/types/payment-type'

type Props = {
    customer: OrderCustomer;
    delivery?: OrderDriver;
    paymentInfo?: PaymentInfo
    paymentType: PaymentType;
    shippingAddress: OrderAddress;
    note: string;
    suitableTime?: number;
};

export default function OrderDetailsInfo({
                                             customer,
                                             delivery,
                                             paymentInfo,
                                             shippingAddress,
                                             note,
                                             paymentType,
                                             suitableTime,
                                         }: Props) {
    const renderCustomer = (
        <>
            <CardHeader title="Kullanıcı Bilgileri" />
            <Stack direction="row" sx={{ p: 3 }}>
                <Avatar
                    alt={customer.name}
                    src={'/assets/avatar.png'}
                    sx={{ width: 48, height: 48, mr: 2 }}
                />

                <Stack spacing={0.5} alignItems="flex-start" sx={{ typography: 'body2' }}>
                    <Typography variant="subtitle2">{customer.name}</Typography>
                    <Box sx={{ color: 'text.secondary' }}>{customer.phone}</Box>
                </Stack>
            </Stack>
        </>
    )

    const renderDelivery = (
        <>
            <CardHeader title="Teslimat Bilgileri" />
            <Stack spacing={1.5} sx={{ p: 3, typography: 'body2' }}>
                <Stack direction="row" alignItems="center">
                    <Box component="span" sx={{ color: 'text.secondary', width: 120, flexShrink: 0 }}>
                        Sürücü
                    </Box>
                    {delivery?.name}
                </Stack>
                <Stack direction="row" alignItems="center">
                    <Box component="span" sx={{ color: 'text.secondary', width: 120, flexShrink: 0 }}>
                        Telefon
                    </Box>
                    {delivery?.phone}
                </Stack>
                <Stack direction="row" alignItems="center">
                    <Box component="span" sx={{ color: 'text.secondary', width: 120, flexShrink: 0 }}>
                        Teslimat Uygun Zamanı
                    </Box>
                    <SuitabilityTimeLabel time={suitableTime} />
                </Stack>

            </Stack>
        </>
    )

    const renderShipping = (
        <>
            <CardHeader title="Adres Bilgileri" />
            <Stack spacing={1.5} sx={{ p: 3, typography: 'body2' }}>
                <Stack direction="row" alignItems="center">
                    <Box component="span" sx={{ color: 'text.secondary', width: 120, flexShrink: 0 }}>
                        Addres
                    </Box>
                    {shippingAddress.address}
                </Stack>
            </Stack>
        </>
    )

    const renderNotes = (
        <>
            <CardHeader title="Siparis Notu" />
            <Stack spacing={0.5} sx={{ p: 3 }}>
                <Stack direction="row" alignItems="center" sx={{ typography: 'body2' }}>
                    <Box component="span" sx={{ color: 'text.secondary', flexGrow: 1 }}>
                        Not
                    </Box>
                    <Typography variant="subtitle2">{note}</Typography>
                </Stack>
            </Stack>
        </>
    )

    const renderPayment = (
        <>
            <CardHeader title="Ödeme Bilgileri" />
            <Stack spacing={0.5} sx={{ p: 3 }}>
                <Stack direction="row" alignItems="center" sx={{ typography: 'body2' }}>
                    <Box component="span" sx={{ color: 'text.secondary', flexGrow: 1 }}>

                    </Box>
                    {paymentInfo && `**** **** **** ${paymentInfo.cardLastDigit}`}
                    {paymentInfo ?
                        <PaymentCardIcon width={30} sx={{ ml: 0.5 }} brand={paymentInfo.cardBrand} />
                        : <Typography variant="subtitle2">{getPaymentLabel(paymentType)}</Typography>
                    }
                </Stack>
                <Typography variant="caption" sx={{ color: 'text.disabled' }}>
                    {paymentInfo && `Bu ödeme iyizico firma ile yapılmıştır. ${paymentInfo.id} kullanarak iyizico panelinden detayları görebilirsiniz.`}
                </Typography>
            </Stack>
        </>
    )

    return (
        <Card>
            {renderCustomer}

            <Divider sx={{ borderStyle: 'dashed' }} />

            {renderDelivery}

            <Divider sx={{ borderStyle: 'dashed' }} />

            {renderShipping}

            <Divider sx={{ borderStyle: 'dashed' }} />

            {renderPayment}

            <Divider sx={{ borderStyle: 'dashed' }} />
            {renderNotes}
        </Card>
    )
}
