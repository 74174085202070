import React, { useCallback, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import Card from '@mui/material/Card'
import Table from '@mui/material/Table'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Tooltip from '@mui/material/Tooltip'
import Container from '@mui/material/Container'
import TableBody from '@mui/material/TableBody'
import IconButton from '@mui/material/IconButton'
import TableContainer from '@mui/material/TableContainer'
import Iconify from 'src/components/iconify'
import { emptyRows, TableEmptyRows, TableHeadCustom, TableNoData, TablePaginationCustom, TableSelectedAction, useTable } from 'src/components/table'
import { Today } from '../../dashboard/chart-common-input'
import OrderReportAnalytic from './order-report-analytic'
import { OrderReport, Period } from 'src/service/types/report'
import OrderReportTableToolbar from './order-report-table-toolbar'
import OrderReportTableRow from './order-table-row'
import { useOrderReportService } from '../../service/order-report-service'
import { useNotify } from 'react-admin'
import { useIsCompany } from '../../login/user-provider'


const TABLE_HEAD = [
    { id: 'title', label: 'Title' },
    { id: 'count', label: 'Toplam Siparisler' },
    { id: 'revenue', label: 'Gelir' },
    { id: 'cost', label: 'Gider' },
    { id: 'profit', label: 'Kazan' },
    { id: 'cashOrders', label: 'Nakit Siparisler' },
    { id: 'cashAmount', label: 'Nakit Tutar' },
    { id: 'onlineOrders', label: 'Online Siparisler' },
    { id: 'onlineAmount', label: 'Online Tutar' },
    { id: 'commission', label: 'Komisyon' },
    { id: 'commissionRate', label: 'Komisyon Oranı' },
]


export default function OrderReportListView() {
    const theme = useTheme()
    const table = useTable()
    const [date, SetDate] = useState(Today)
    const [period, setPeriod] = useState(Period.DAY)
    const [report, setReport] = useState<OrderReport>()
    const orderService = useOrderReportService()
    const notify = useNotify()
    const isCompany = useIsCompany()

    const handleReport = useCallback(async (period: Period, start: Date, end: Date) => {
        await orderService.getReport(start.getTime(), end.getTime(), period)
            .then(setReport).catch(() => {
                notify('Bir hata oluştu. Lütfen tekrar deneyin.', { type: 'error' })
            })
    }, [])

    React.useEffect(() => {
        void handleReport(period, date.start, date.end)
    }, [date, period])

    const denseHeight = table.dense ? 56 : 76
    const tableData = report?.items ?? []

    return (
        <>
            <Container maxWidth={false}>

                <Card
                    sx={{
                        mb: { xs: 3, md: 5 },
                    }}
                >
                    <Stack
                        direction="row"
                        spacing={2}
                        divider={<Divider orientation="vertical" flexItem sx={{ borderStyle: 'dashed' }} />}
                        sx={{ py: 2, overflowX: 'auto' }}
                    >
                        <OrderReportAnalytic
                            title="Toplam Gelir"
                            price={report?.totalRevenue ?? 0}
                            icon="solar:bill-list-bold-duotone"
                            color={theme.palette.info.main}
                        />

                        {!isCompany && <OrderReportAnalytic
                            title="Toplam Kazanç"
                            price={report?.totalProfit ?? 0}
                            icon="solar:file-check-bold-duotone"
                            color={theme.palette.success.main}
                        />
                        }

                        {!isCompany && <OrderReportAnalytic
                            title="Toplam Gider"
                            price={report?.totalCost ?? 0}
                            icon="solar:sort-by-time-bold-duotone"
                            color={theme.palette.warning.main}
                        />
                        }

                        <OrderReportAnalytic
                            title="Toplam Komisyon"
                            price={report?.totalCommission ?? 0}
                            icon="solar:bell-bing-bold-duotone"
                            color={theme.palette.error.main}
                        />

                        <OrderReportAnalytic
                            title="Toplam Siparişler"
                            total={report?.totalOrders ?? 0}
                            icon="solar:file-corrupted-bold-duotone"
                            color={theme.palette.text.secondary}
                        />
                        <OrderReportAnalytic
                            icon={'solar:file-corrupted-bold-duotone'}
                            title={'Toplam Nakit Siparişler'}
                            total={report?.totalCashOrders ?? 0}
                            color={theme.palette.text.secondary} />
                        <OrderReportAnalytic
                            icon={'solar:file-corrupted-bold-duotone'}
                            title={'Toplam Nakit Odeme'}
                            price={report?.totalCashAmount ?? 0}
                            color={theme.palette.success.main} />
                        <OrderReportAnalytic
                            icon={'solar:file-corrupted-bold-duotone'}
                            title={'Toplam Online Siparişler'}
                            total={report?.totalOnlineOrders ?? 0}
                            color={theme.palette.success.main} />
                        <OrderReportAnalytic
                            icon={'solar:file-corrupted-bold-duotone'}
                            title={'Toplam Online Odeme'}
                            price={report?.totalOnlineAmount ?? 0}
                            color={theme.palette.success.main} />
                    </Stack>
                </Card>

                <Card>

                    <OrderReportTableToolbar period={period} onDate={SetDate} onPeriod={setPeriod} />

                    <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                        <TableSelectedAction
                            dense={table.dense}
                            numSelected={table.selected.length}
                            rowCount={tableData.length}
                            action={
                                <Stack direction="row">
                                    <Tooltip title="Download">
                                        <IconButton color="primary">
                                            <Iconify icon="eva:download-outline" />
                                        </IconButton>
                                    </Tooltip>

                                </Stack>
                            }
                        />
                        <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                            <TableHeadCustom
                                order={table.order}
                                orderBy={table.orderBy}
                                headLabel={TABLE_HEAD.filter(item => !isCompany || (item.id !== 'cost' && item.id !== 'profit'))}
                                rowCount={tableData.length}
                                numSelected={table.selected.length}
                                onSort={table.onSort}
                            />

                            <TableBody>
                                {tableData
                                    .slice(
                                        table.page * table.rowsPerPage,
                                        table.page * table.rowsPerPage + table.rowsPerPage,
                                    )
                                    .map((row) => (
                                        <OrderReportTableRow
                                            key={row.time.start}
                                            row={row}
                                            showCost={!isCompany}
                                        />
                                    ))}

                                <TableEmptyRows
                                    height={denseHeight}
                                    emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                                />

                                <TableNoData notFound={tableData.length === 0} />
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePaginationCustom
                        count={tableData.length}
                        page={table.page}
                        rowsPerPage={table.rowsPerPage}
                        onPageChange={table.onChangePage}
                        onRowsPerPageChange={table.onChangeRowsPerPage}
                        dense={table.dense}
                        onChangeDense={table.onChangeDense}
                    />
                </Card>
            </Container>
        </>
    )
}