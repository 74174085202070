import { BaseCrudService } from '../base-crud-service'
import { AxiosInstance } from 'axios'
import { useRestService } from '../base-service'
import { ProductUnit } from '../types/product'

export const useProductUnitService = () => {
    const api = useRestService()
    return new ProductUnitService(api)
}

class ProductUnitService extends BaseCrudService<ProductUnit,void> {

    constructor(api: AxiosInstance) {
        super(api, '/productUnits')
    }
}