import { ApiAuthProvider } from '../service/api-auth-provider'
import { AuthProvider } from 'ra-core'


export const authProvider: AuthProvider = {
    login: async () => {
        // do nothing
    },
    logout: async () => {
        ApiAuthProvider.INSTANCE.setSession(undefined)
    },
    checkAuth: async () => {
        const session = ApiAuthProvider.INSTANCE.getSession()
        if (session) {
            return
        }
        ApiAuthProvider.INSTANCE.setSession(undefined)
        throw new Error('Lutfen tekrar giris yapin')
    },
    checkError: async ({ status }) => {
        if (status === 401 || status === 403) {
            ApiAuthProvider.INSTANCE.setSession(undefined)
            return
        } else if (ApiAuthProvider.INSTANCE.getSession()) {
            return
        }
        ApiAuthProvider.INSTANCE.setSession(undefined)
        throw new Error('Lutfen tekrar giris yapin')
    },
    getPermissions: async () => {
        const user = ApiAuthProvider.INSTANCE.getSession()
        if (user?.authority) {
            return user.authority
        }
        ApiAuthProvider.INSTANCE.setSession(undefined)
        throw new Error('Lutfen tekrar giris yapin')
    },
}


