export const PaymentListTranslation = {
    tr: {
        'payment-reference': {
            name: 'Ödeme Referansları',
            fields: {
                'paymentId': 'Ödeme ID',
                'amount': 'Miktar',
                'cardNumber': 'Kart Numarası',
                'currency': 'Para Birimi',
                'status': 'Durum',
                'refundTime': 'İade Zamanı',
                'order.status': 'Sipariş Durumu',
                'paymentTime': 'Ödeme Zamanı',
                'installments': 'Taksit',
            },
        },
    },
}

export const paymentStatusChoices = [{
    id: 'SUCCESS',
    name: 'Ödenmiş',
}, {
    id: 'REFUNDED',
    name: 'Iade Edilmiş',
}]