import { AxiosInstance } from 'axios'
import { Order, OrderHistory, OrderUpdateRequest } from './types/order'
import { useRestService } from './base-service'
import { Driver } from './types/user'
import download from 'downloadjs'

export const useOrderService = () => {
    const api = useRestService()
    return new OrderService(api)
}

class OrderService {
    private readonly api: AxiosInstance

    constructor(api: AxiosInstance) {
        this.api = api
    }

    public async getPending(): Promise<Order[]> {
        return await this.api.get('/orders/getPending').then(it => it.data)
    }

    public async assignDriver(orderId: string, driverId: string): Promise<void> {
        return await this.api.post(`/orders/assign/${orderId}/${driverId}`).then(it => it.data)
    }

    public async deliverOrder(orderId: string): Promise<void> {
        return await this.api.post(`/orders/deliver/${orderId}`).then(it => it.data)
    }

    public async onWayOrder(orderId: string): Promise<void> {
        return await this.api.post(`/orders/onWay/${orderId}`).then(it => it.data)
    }

    public async cancelOrder(orderId: string, reason?: string): Promise<void> {
        const data = reason ? { message: reason } : {}
        return await this.api.post(`/orders/cancel/${orderId}`, data, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },

        }).then(it => it.data)
    }

    public async getDrivers(): Promise<Driver[]> {
        return await this.api.get('/drivers/getList').then(it => it.data)
    }

    public async getReceipt(orderId: string): Promise<string> {
        return await this.api.get(`/orders/receipt/${orderId}`).then(it => it.data)
    }

    public async get(id: string): Promise<Order> {
        return await this.api.get(`/orders/getOne/${id}`).then(it => it.data)
    }

    public async getHistory(id: string): Promise<OrderHistory> {
        return await this.api.get(`/orders/getHistory/${id}`).then(it => it.data)
    }

    public async printReceipt(orderId: string): Promise<void> {
        await this.api.get(`/orders/receipt/print/${orderId}`, {
            responseType: 'blob',
        }).then(it => {
            let filename = ''
            const disposition = it.headers['Content-Disposition']
            if (disposition && disposition.indexOf('inline') !== -1) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
                const matches = filenameRegex.exec(disposition)
                if (matches?.[1]) {
                    filename = matches[1].replace(/['"]/g, '')
                }
            }
            const fileType = 'application/pdf'
            download(it.data, filename, fileType)
        })
    }

    public async updateOrder(order: OrderUpdateRequest): Promise<Order> {
        return await this.api.patch(`/orders/update`, order, {
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(it => it.data)
    }

    public async getBill(orderId: string): Promise<string> {
        return await this.api.get(`/orders/efatura/${orderId}`).then(it => it.data.url)
    }
}