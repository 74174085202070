import React, { useEffect, useState } from 'react'
import PendingOrder from './pending/pending-order'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Unstable_Grid2'
import AnalyticsWidgetSummary from './analytics-widget-summary'
import { useSaleReportService } from '../service/sale-report-service'
import { Period, SalesReport } from '../service/types/report'
import { useNotify } from 'react-admin'
import { fCurrency, fNumber } from '../utils/format-number'
import LinearProgressView from '../common/linear-progress-view'
import Iconify from '../components/iconify'
import ChartCommonInput, { DateInput, Today } from './chart-common-input'
import AnalyticsPaymentMethods from './analytics-payment-methods'
import OrderCountChart from './order-count-statistics'
import IncomeChartsView from './income-charts-view'
import { isUnauthorizedError } from '../utils/error+'
import { useIsCompany } from '../login/user-provider'

export default function Dashboard() {
    const reportService = useSaleReportService()
    const [report, setReport] = useState<SalesReport>()
    const notify = useNotify()
    const [date, setDate] = useState<DateInput>(Today)
    const [period, setPeriod] = useState(Period.MONTH)
    const isCompany = useIsCompany()
    const fetchReport = async () => {
        try {
            const res = date ? await reportService.getReport(date.start.getTime(), date.end.getTime()) : await reportService.getTodayReport()
            setReport(res)
        } catch (e) {
            if (isUnauthorizedError(e)) {
                return
            }
            notify('Rapor yüklenirken bir hata oluştu', { type: 'error' })
        }
    }
    useEffect(() => {
        void fetchReport()
    }, [date])

    if (!report) {
        return <LinearProgressView />
    }
    const onlinePayment = report.paymentMethods.find((i) => i.methodId === 'CREDIT_CARD')
    return (
        <Container maxWidth={false} sx={{ overflowY: 'scroll', mr: '-10px' }}>
            <Grid container spacing={3}>
                <Grid xs={12} sm={12} md={12}>
                    <PendingOrder />
                </Grid>
                <ChartCommonInput onChange={setDate} period={period} onPeriodChange={setPeriod} />
                <Grid xs={12} sm={12}>
                    <IncomeChartsView start={date.start} end={date.end} period={period} />
                </Grid>
                <Grid xs={12} sm={6} md={3} xl={2}>
                    <AnalyticsWidgetSummary
                        title="Satışlar"
                        total={fCurrency(report.revenue)}
                        color="success"
                        icon={<img alt="icon" src="/assets/icons/glass/ic_glass_bag.png" />}
                    />
                </Grid>

                {!isCompany && <Grid xs={12} sm={6} md={3} xl={2}>
                    <AnalyticsWidgetSummary
                        title="Harcamalar"
                        total={fCurrency(report.cost)}
                        color="warning"
                        icon={<img alt="icon" src="/assets/icons/glass/ic_glass_bag.png" />}
                    />
                </Grid>
                }

                {!isCompany && <Grid xs={12} sm={6} md={3} xl={2}>
                    <AnalyticsWidgetSummary
                        title="Kazanç"
                        total={fCurrency(report.profit)}
                        color="success"
                        icon={<Iconify icon={'game-icons:profit'}
                                       sx={{ width: 50, height: 50 }} />}
                    />
                </Grid>
                }

                <Grid xs={12} sm={6} md={3} xl={2}>
                    <AnalyticsWidgetSummary
                        title="Tamamlanan Siparişler"
                        total={fNumber(report.total)}
                        color="info"
                        icon={<img alt="icon" src="/assets/icons/glass/ic_glass_buy.png" />}
                    />
                </Grid>
                <Grid xs={12} sm={6} md={3} xl={2}>
                    <AnalyticsWidgetSummary
                        title="Ödenecek Komisyon"
                        total={fCurrency(report.commission)}
                        color="error"
                        icon={<img alt="icon" src="/assets/icons/glass/ic_glass_message.png" />}
                    />
                </Grid>
                <Grid xs={12} sm={6} md={3} xl={2}>
                    <AnalyticsWidgetSummary
                        title="Online Ödeme"
                        total={fCurrency(onlinePayment?.total ?? 0.0)}
                        color="error"
                        icon={<img alt="icon" src="/assets/icons/glass/ic_glass_message.png" />}
                    />
                </Grid>

                <Grid xs={12} sm={6} md={6} xl={6}>
                    <AnalyticsPaymentMethods
                        title="Ödeme Yöntemleri"
                        chart={{
                            series: report.paymentMethods.map((i) => ({
                                label: `${i.methodName} (${fCurrency(i.total)})`,
                                value: i.count,
                            })),
                        }} />
                </Grid>
                <Grid xs={12} sm={6} md={6} xl={6}>
                    <AnalyticsPaymentMethods
                        title="Teslimatlar"
                        chart={{
                            series: report.deliveries.map((i) => ({
                                label: i.driver + ` (${fCurrency(i.total)})`,
                                value: i.count,
                            })),
                        }} />
                </Grid>
                <Grid xs={12} sm={6} md={6} xl={6}>
                    <OrderCountChart start={date?.start} end={date?.end} />
                </Grid>
            </Grid>
        </Container>
    )
}
