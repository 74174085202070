export type Message = { severity: 'error' | 'success'; text: string } | null

export type NotificationItem = {
    id: string
    subject: string
    message: string
    badge: boolean
    badgeSeverity: 'error' | 'success' | 'warning'
    badgeMessage: string
    date: string
    unread: boolean
}

export type Localized = {
    turkish: string
    english: string
}

export type Page<T> = {
    content: T[]
    totalPages: number
    totalElements: number
    last: boolean
    size: number
    number: number
    first: boolean
    numberOfElements: number
    empty: boolean
}

export type PageQuery = {
    page: number
    size: number
    sort: string
    direction: 'asc' | 'desc'
}

export const DefaultPageQuery: PageQuery = {
    page: 0,
    size: 10000,
    sort: 'createdAt',
    direction: 'desc',
}

export type Query = {
    pagination?: PageQuery,
    query?: Record<string, any>
}
