import { m } from 'framer-motion'
import { SxProps, Theme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Iconify from 'src/components/iconify'
import { varHover } from 'src/components/animate'
import { useSettingsContext } from 'src/components/settings'
import React from 'react'

type Props = {
    sx?: SxProps<Theme>
}

export default function SettingsButton({ sx }: Props) {
    const settings = useSettingsContext()

    return (
        <Box>
            <IconButton
                component={m.button}
                whileTap="tap"
                whileHover="hover"
                variants={varHover(1.05)}
                aria-label="settings"
                onClick={settings.onToggle}
                sx={{
                    width: 40,
                    height: 40,
                }}
            >
                <Iconify icon="solar:settings-bold-duotone" width={24} />
            </IconButton>
        </Box>
    )
}
