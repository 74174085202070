import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useBoolean } from '../../hooks/use-boolean'
import { RHFAutocomplete } from '../../components/hook-form'
import debounce from '@mui/utils/debounce'
import { useProductUnitService } from '../../service/crud/product-unit-service'
import { ProductUnit } from '../../service/types/product'
import { RHFAutocompleteProps } from '../../components/hook-form/rhf-autocomplete'

export type ProductUnitReferenceInputProps = {
    name: string
    label: string
} & Omit<RHFAutocompleteProps<ProductUnit, false, false, false>, 'options'>
export default function ProductUnitReferenceInput({ name, label, ...others }: ProductUnitReferenceInputProps) {
    const service = useProductUnitService()
    const [units, setUnits] = React.useState<ProductUnit[]>([])
    const [search, setSearch] = useState('')
    const loading = useBoolean(false)

    const fetchUnits = useCallback(async (name?: string) => {
        loading.onTrue()
        const filter = name ? { name } : undefined
        await service.getAll({ query: filter }).then((data) => {
            setUnits(data)
        }).catch((error) => {
            console.error(error)
        }).finally(loading.onFalse)
    }, [])

    const handleSearch = useMemo(() => {
        return debounce((value: string) => {
            void fetchUnits(value)
        }, 500)
    }, [])
    useEffect(() => {
        handleSearch(search)
    }, [search])
    return (
        <RHFAutocomplete
            name={name}
            label={label}
            loading={loading.value}
            onInputChange={(_, value) => {
                setSearch(value)
            }}
            getOptionId={(option) => option.id}
            isOptionEqualToValue={(option, value) => {
                if (typeof value === 'string') {
                    return units.find((category) => category.id === value) !== undefined
                }
                return option.id === value.id
            }}
            getOptionKey={(option) => typeof option === 'object' ? option.id : option}
            getOptionLabel={(option) => typeof option === 'object' ? option.name : units.find((category) => category.id === option)?.name ?? ''}
            options={units}
            {...others}
        />
    )
}