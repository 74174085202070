import React from 'react'
import Iconify from '../components/iconify'
import { getPaymentCardIcon } from '../utils/period+'
import IyziCardIcon from '../images/iyizico.svg'
import { BoxProps } from '@mui/material/Box'

type Props = {
    brand: string
    width?: number
    height?: number
} & BoxProps

export default function PaymentCardIcon({ brand, width, height, sx }: Props) {
    const icon = getPaymentCardIcon(brand)
    if (!icon) {
        return <img src={IyziCardIcon} width={width ?? 24} height={height ?? 24} alt={'card'} />
    }
    return <Iconify icon={icon} width={width ?? 24} height={height ?? 24} sx={sx} />
}