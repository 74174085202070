import { AxiosInstance } from 'axios'
import { useRestService } from './base-service'
import { PaymentType, PaymentTypesUpdateRequest } from './types/payment-type'

export const usePaymentTypeService = () => {
    const api = useRestService()
    return new PaymentTypeService(api)
}

class PaymentTypeService {
    private readonly api: AxiosInstance

    constructor(api: AxiosInstance) {
        this.api = api
    }


    public async updateActivePayments(active: string[]): Promise<PaymentType[]> {
        const request: PaymentTypesUpdateRequest = {
            activeTypes: active,
        }
        return await this.api
            .put(`/payment-types/update`, request, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((it) => it.data)
    }

    public async getAll(): Promise<PaymentType[]> {
        return await this.api.get('/payment-types/get').then(it => it.data)
    }
}
