import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import FormHelperText from '@mui/material/FormHelperText'
import FormProvider from 'src/components/hook-form'
import { usePaymentTypeService } from '../../service/payment-type-service'
import Alert from '@mui/material/Alert'
import { useBoolean } from '../../hooks/use-boolean'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import { PaymentType } from '../../service/types/payment-type'
import { Message } from '../../service/types/common'
import Typography from '@mui/material/Typography'
import { alpha } from '@mui/material/styles'

export default function PaymentTypesView() {
    const [message, setMessage] = useState<Message>(null)
    const loading = useBoolean(false)
    const paymentTypeService = usePaymentTypeService()
    const [paymentTypes, setPaymentTypes] = useState<PaymentType[]>([])
    const [activePaymentTypes, setActivePaymentTypes] = useState<string[]>([])

    const UpdatePaymentTypesSchema = Yup.object().shape({
        activeTypes: Yup.array().of(Yup.string()).required('En az bir ödeme yöntemi seçmelisiniz'),
    })

    const defaultValues = {
        activeTypes: activePaymentTypes,
    }

    const methods = useForm({
        resolver: yupResolver(UpdatePaymentTypesSchema),
        defaultValues,
    })

    const {
        handleSubmit,
        reset,
        control,
        watch,
        formState: { isSubmitting },
    } = methods
    const actives = watch('activeTypes')

    useEffect(() => {
        reset(defaultValues)
    }, [activePaymentTypes])

    useEffect(() => {
        const fetchPaymentTypes = async () => {
            loading.onTrue()
            await paymentTypeService
                .getAll()
                .then((data) => {
                    setPaymentTypes(data)
                    setActivePaymentTypes(data.filter(pt => pt.active).map(pt => pt.id))
                })
                .catch((e) => {
                    setMessage({ severity: 'error', text: e.message })
                })
                .finally(loading.onFalse)
        }
        void fetchPaymentTypes()
    }, [])

    const onSubmit = handleSubmit(async (data) => {
        try {
            setMessage(null)
            // @ts-ignore
            const active: string[] = data.activeTypes.filter((id) => id)
            await paymentTypeService.updateActivePayments(active)
            setMessage({ severity: 'success', text: 'Başarıyla güncellendi' })
            setTimeout(() => {
                setMessage(null)
            }, 2000)
        } catch (error) {
            setMessage({ severity: 'error', text: error.message })
        }
    })

    return (
        <FormProvider methods={methods} onSubmit={onSubmit}>
            <Card sx={{ p: 4, pt: 6 }}>
                <Alert severity="warning" sx={{ mb: 2, width: '50%', fontWeight: 'bold' }}>
                    Aktif ödeme tiplerini seçebilirsiniz. En az bir ödeme tipi seçmelisiniz. Dikkat: Eğer bir ödeme tipini kaldırırsanız, müşteriler
                    bu ödeme tipi ile ödeme yapamazlar.
                </Alert>
                <Box display="flex" flexDirection="column" rowGap={1}>
                    {paymentTypes.map((paymentType) => (
                        <Stack sx={{
                            m: 1,
                            p: 1,
                            width: '50%',
                            borderRadius: 1,
                            backgroundColor: theme => actives.includes(paymentType.id) ? alpha(theme.palette.success.main, 0.1) : alpha(theme.palette.error.main, 0.2),
                        }}
                               key={paymentType.id}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}>
                                <Typography variant="h6">
                                    {paymentType.name}
                                </Typography>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            name="activeTypes"
                                            control={control}
                                            render={({ field }) => (
                                                <Switch
                                                    {...field}
                                                    value={paymentType.id}
                                                    checked={field.value.includes(paymentType.id)}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            field.onChange([...field.value, paymentType.id])
                                                        } else {
                                                            field.onChange(field.value.filter((id) => id !== paymentType.id))
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                    }
                                    label=""
                                />
                            </Box>
                            <FormHelperText>
                                {actives.includes(paymentType.id)
                                    ? 'Bu ödeme tipi aktif ve kullanılabilir.'
                                    : 'Bu ödeme tipi pasif ve müşteriler için sorun yaratabilir.'}
                            </FormHelperText>
                        </Stack>
                    ))}
                </Box>

                {message && (
                    <Alert severity={message.severity} sx={{ mt: 2 }}>
                        {message.text}
                    </Alert>
                )}
                <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
                    <LoadingButton
                        type="submit"
                        color={'primary'}
                        variant="contained"
                        disabled={message?.severity === 'success'}
                        loading={isSubmitting}
                    >
                        {isSubmitting ? 'Güncelleniyor...' : 'Güncelle'}
                    </LoadingButton>
                </Stack>
            </Card>
        </FormProvider>
    )
}