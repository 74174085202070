import React, { useCallback, useEffect, useState } from 'react'
import { CardProps } from '@mui/material/Card'
import { IncomeReport, Period } from '../service/types/report'
import { useSaleReportService } from '../service/sale-report-service'
import { useNotify } from 'react-admin'
import Grid from '@mui/material/Unstable_Grid2'
import IncomeCardView from './income-card-view'
import { useIsCompany } from '../login/user-provider'

type Props = {
    start: Date
    end: Date
    period: Period
} & CardProps

export default function IncomeChartsView({ start, end, period }: Props) {
    const service = useSaleReportService()
    const notify = useNotify()
    const [income, setIncome] = useState<IncomeReport>()
    const isCompany = useIsCompany()


    const fetchIncome = useCallback(async (period: Period, start: Date, end: Date) => {
        await service.getIncomeReport(start.getTime(), end.getTime(), period)
            .then(setIncome)
            .catch(() => {
                notify('Failed to fetch statistics', { type: 'error' })
            })
    }, [])

    useEffect(() => {
        void fetchIncome(period, start, end)
    }, [period, start, end])

    if (!income) {
        return <></>
    }

    return (
        <>
            <Grid container spacing={3}>
                <Grid xs={12} md={4}>
                    <IncomeCardView title={'Gelir'}
                                    total={income.totalEarnings}
                                    color={'success'}
                                    percent={income.trendEarnings}
                                    icon={income.trendEarnings > 0 ? 'eva:diagonal-arrow-right-up-fill' : 'eva:diagonal-arrow-left-down-fill'}
                                    chart={{
                                        series: income.earnings.map((item) => ({
                                            x: item.title,
                                            y: item.total,
                                        })),
                                    }} />
                </Grid>
                {!isCompany && <Grid xs={12} md={4}>
                    <IncomeCardView title={'Gider'}
                                    total={income.totalExpenses}
                                    color={'error'}
                                    percent={income.trendExpenses}
                                    icon={income.trendExpenses > 0 ? 'eva:diagonal-arrow-right-up-fill' : 'eva:diagonal-arrow-left-down-fill'}
                                    chart={{
                                        series: income.expenses.map((item) => ({
                                            x: item.title,
                                            y: item.total,
                                        })),
                                    }} />

                </Grid>
                }
                {!isCompany && <Grid xs={12} md={4}>
                    <IncomeCardView title={'Kazanç'}
                                    total={income.totalProfit}
                                    color={'error'}
                                    percent={income.trendProfit}
                                    icon={income.trendExpenses > 0 ? 'eva:diagonal-arrow-right-up-fill' : 'eva:diagonal-arrow-left-down-fill'}
                                    chart={{
                                        series: income.profit.map((item) => ({
                                            x: item.title,
                                            y: item.total,
                                        })),
                                    }} />
                </Grid>
                }
            </Grid>
        </>
    )
}
