import React from 'react'
import Icon from '@mui/icons-material/DriveEtaRounded'
import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    DateField,
    DeleteWithConfirmButton,
    Edit,
    EditButton,
    email,
    List,
    ReferenceManyField,
    regex,
    required,
    Show,
    ShowButton,
    SimpleForm,
    Tab,
    TabbedShowLayout,
    TextField,
    TextInput,
} from 'react-admin'
import ImageUploadInput from '../../common/image-upload-input'
import { OrdersGird } from '../order/order'

export const DriverIcon = Icon

export const DriverList = () => {
    return <List exporter={false}>
        <Datagrid bulkActionButtons={false}>
            <TextField source="name" />
            <TextField source="email" />
            <TextField source="username" />
            <BooleanField source="enabled" />
            <DateField source="createdAt" />
            <ShowButton />
            <EditButton />
            <DeleteWithConfirmButton />
        </Datagrid>
    </List>
}

export const DriverCreate = () => {
    return <Create>
        <SimpleForm>
            <TextInput name={'name'} source={'name'} validate={required()} />
            <TextInput name={'email'} source={'email'} validate={[required(), email()]} />
            <TextInput name={'username'} source={'username'}
                       helperText={'Sadece turk hat numarası kabul edilir. Örn: 905xxxxxxxxx'}
                       validate={[required(), regex(/^905\d{9}$/, 'Telefon numarası doğru formatta olmalıdır')]} />
            <ImageUploadInput source={'image'} sx={{}} required={false} />
        </SimpleForm>
    </Create>
}
export const DriverEdit = () => {
    return <Edit>
        <SimpleForm>
            <TextInput name={'name'} source={'name'} validate={required()} />
            <TextInput name={'email'} source={'email'} validate={[required(), email()]} />
            <TextInput name={'username'} source={'username'}
                       helperText={'Sadece turk hat numarası kabul edilir. Örn: 905xxxxxxxxx'}
                       validate={[required(), regex(/^905\d{9}$/, 'Telefon numarası doğru formatta olmalıdır')]} />
            <ImageUploadInput source={'image'} sx={{}} required={false} />
            <BooleanInput name={'enabled'} source={'enabled'} />
        </SimpleForm>
    </Edit>
}

export const DriverShow = () => {
    return <Show>
        <TabbedShowLayout>
            <Tab label={'Genel'}>
                <TextField source="name" />
                <TextField source="email" />
                <TextField source="username" />
                <BooleanField source="enabled" />
                <DateField source="createdAt" />
            </Tab>
            <Tab label={'Siparişler'}>
                <ReferenceManyField reference="orders" target="driver.id" label="Siparişler">
                    <OrdersGird />
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
}