import { LoggedUser, LoginResponse } from './types/user'
import { AxiosInstance } from 'axios'
import { ApiAuthProvider } from './api-auth-provider'
import { createAxios } from './base-service'
import { globalConfig } from '../global-config'


export const useAuthService = () => {
    return new AuthService()
}

export class AuthService {
    private readonly api: AxiosInstance
    private readonly clientId = globalConfig.api.clientId
    private readonly clientSecret = globalConfig.api.clientSecret

    constructor() {
        this.api = createAxios()
    }

    public async login(username: string, password: string, captcha: string, marketToken?: string): Promise<LoginResponse> {
        return await this.api.post<LoggedUser>('/admins/login', {
            username,
            password,
            marketToken,
            captcha,
            clientId: this.clientId,
            clientSecret: this.clientSecret,
        })
            .then(({ data }) => {
                return data
            })
    }

    public async verify(token: string, code: string): Promise<LoggedUser> {
        return await this.api.post('/admins/verify', { token, code })
            .then(({ data }) => {
                ApiAuthProvider.INSTANCE.setSession(data)
                return data
            })
    }
}