import Stack from '@mui/material/Stack'
import ChartCommonInput, { DateInput } from '../../dashboard/chart-common-input'
import { Period } from '../../service/types/report'
import React from 'react'

type Props = {
    period: Period;
    onDate: (date: DateInput) => void;
    onPeriod: (period: Period) => void;
};

export default function OrderReportTableToolbar({ period, onDate, onPeriod }: Props) {

    return (
        <>
            <Stack
                spacing={2}
                alignItems={{ xs: 'flex-end', md: 'center' }}
                direction={{
                    xs: 'column',
                    md: 'row',
                }}
                sx={{
                    p: 2.5,
                    pr: { xs: 2.5, md: 1 },
                }}
            >
                <ChartCommonInput onChange={onDate} period={period} onPeriodChange={onPeriod} />
            </Stack>
        </>
    )
}
