import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import 'numeral/locales'

const element = document.getElementById('root') as HTMLElement
const root = ReactDOM.createRoot(element)
root.render(<App/>)
serviceWorker.unregister()
