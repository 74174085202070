import { Controller, useFormContext } from 'react-hook-form'
import React from 'react'
import { DatePicker, DatePickerProps, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { trTR } from '@mui/x-date-pickers/locales'
import trLocale from 'date-fns/locale/tr'

type Props = DatePickerProps<any> & {
    name: string
    helperText?: string
}

export default function RHFDateField({ name, helperText, ...other }: Props) {
    const { control } = useFormContext()

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}
                                      adapterLocale={trLocale as any}
                                      localeText={trTR.components.MuiLocalizationProvider.defaultProps.localeText}>
                    <DatePicker
                        {...field}
                        value={
                            field.value && typeof field.value.isUTC === 'function' ? field.value : new Date(field.value)
                        }
                        format={'dd.MM.yyyy'}
                        slotProps={{
                            textField: {
                                helperText: error ? error.message : '',
                                fullWidth: true,
                            },
                        }}
                        onChange={(newValue) => {
                            field.onChange(newValue)
                        }}
                        {...other}
                    />
                </LocalizationProvider>
            )}
        />
    )
}
