import React, { useEffect } from 'react'
import Icon from '@mui/icons-material/House'
import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    Edit,
    EditButton,
    maxValue,
    minValue,
    NumberInput,
    ReferenceInput,
    required,
    SelectInput,
    TabbedForm,
    TextField,
} from 'react-admin'
import { ListPagination } from '../common/Pagination'
import { RaFixedList } from '../common/ra-fixed-list'
import { useFormContext, useWatch } from 'react-hook-form'
import TownDeliveryFeeInput from './town-delivery-fee-input'

export const TownIcon = Icon

export const TownList = () => (
    <RaFixedList sort={{ field: 'id', order: 'DESC' }} perPage={50} pagination={<ListPagination />}>
        <Datagrid bulkActionButtons={false}>
            <TextField source={'name'} />
            <TextField source={'districtName'} />
            <TextField source={'minimumOrderAmount'} />
            <TextField source={'minimumDeliveryMinutes'} />
            <TextField source={'maximumDeliveryMinutes'} />
            <BooleanField source={'active'} />
            <EditButton />
        </Datagrid>
    </RaFixedList>
)

export const TownCreate = () => (
    <Create redirect={'list'}>
        <CreateOrEdit create />
    </Create>
)

export const TownEdit = () => (
    <Edit redirect={'list'}>
        <CreateOrEdit />
    </Edit>
)

const CreateOrEdit = ({ create }) => (
    <TabbedForm redirect={'list'} syncWithLocation={false}>
        <TabbedForm.Tab label={'resources.towns.general'}>
            {create && <ReferenceInput perPage={1000}
                                       source={'districtId'}
                                       name={'districtId'}
                                       reference={'districts'}>
                <SelectInput optionText={'name'}
                             source={'districtId'}
                             validate={required()} />
            </ReferenceInput>
            }
            {create && <TownInput />}
            <NumberInput source={'minimumOrderAmount'}
                         name={'minimumOrderAmount'}
                         validate={[required(), minValue(1)]} />
            <NumberInput source={'minimumDeliveryMinutes'}
                         name={'minimumDeliveryMinutes'}
                         validate={required()} />
            <NumberInput source={'maximumDeliveryMinutes'}
                         name={'maximumDeliveryMinutes'}
                         validate={required()} />
            <BooleanInput source={'active'} name={'active'} />
        </TabbedForm.Tab>
        <TabbedForm.Tab label={'Saatler'}>
            <NumberInput
                source={'shift.open.hour'}
                label={'Açık Saat'}
                name={'shift.open.hour'}
                options={{ helperText: '0 demek 12 gece.' }}
                validate={[minValue(0), maxValue(23), required()]}
            />
            <NumberInput source={'shift.open.minute'}
                         name={'shift.open.minute'}
                         label={'Açık Dakika'}
                         validate={[required(), minValue(0), maxValue(59)]} />
            <NumberInput label={'Kapalı Saat'}
                         name={'shift.close.hour'}
                         source={'shift.close.hour'}
                         validate={[required(), minValue(0), maxValue(23)]} />
            <NumberInput label={'Kapalı Dakika'}
                         name={'shift.close.minute'}
                         source={'shift.close.minute'}
                         validate={[required(), minValue(0), maxValue(59)]} />
        </TabbedForm.Tab>
        <TabbedForm.Tab label={'Teslimat Ücretleri'}>
            <TownDeliveryFeeInput />
        </TabbedForm.Tab>
    </TabbedForm>
)

const TownInput = () => {
    const city = useWatch({ 'name': 'districtId' })
    const { resetField, formState: { dirtyFields } } = useFormContext()
    useEffect(() => {
        const isDirty = dirtyFields.city !== undefined
        if (isDirty) {
            resetField('remoteId', { defaultValue: [] })
        }
    }, [city])
    return <ReferenceInput name={'remoteId'} source={'remoteId'}
                           filter={{ districtId: city, nonExistentOnly: false }}
                           reference={'remoteTowns'}>
        <SelectInput name={'remoteId'}
                     disabled={!city}
                     error={!city}
                     validate={required()}
                     helperText={!city ? 'Önce ilçe seçmek lazım' : ''}
                     source={'remoteId'} optionText={'name'} />
    </ReferenceInput>
}

export const TownTranslations = {
    tr: {
        towns: {
            name: 'Mahalle',
            general: 'Genel',
            fields: {
                name: 'Adı',
                location: 'Konum',
                active: 'Aktif',
                districtName: 'İlçe',
                deliveryFree: 'Bedava Teslimat?',
                tiers: 'Teslimat Ücretleri',
                startAmount: 'Başlangıç Tutarı',
                endAmount: 'Bitiş Tutarı',
                fee: 'Ücret',
                freeDeliveryLimit: 'Bedava teslimat ücreti alışveriş limiti',
                district: {
                    id: 'İlçe',
                },
            },
        },
    },
}
