import { useRecordContext } from 'react-admin'
import Icon from '@mui/icons-material/SortRounded'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import Button, { ButtonProps } from '@mui/material/Button'

type RearrangeButtonViewProps = {
    path: string
} & ButtonProps
export default function RearrangeButtonView({ path, children, ...others }: RearrangeButtonViewProps) {
    const navigate = useNavigate()
    const record = useRecordContext()
    return (
        <Button onClick={() => {
            navigate(`/${path}/rearrange/${record.id}`)
        }} startIcon={<Icon />}
                {...others}
                color={'primary'}
        >
            {children !== undefined ? children : 'Sırala'}
        </Button>)

}