import { useRestService } from './base-service'
import { UploadResult } from './types/upload'
import { AxiosInstance } from 'axios'

export const useUploadService = () => {
    const api = useRestService()
    return new UploadService(api)
}

class UploadService {
    private readonly api: AxiosInstance

    constructor(api: AxiosInstance) {
        this.api = api
    }

    public async uploadImage(file: File): Promise<string> {
        const formData = new FormData()
        formData.append('file', file)
        return await this.api.post<UploadResult>('/upload/uploadImage', formData).then(res => res.data.url)
    }

    public async deleteImage(url: string): Promise<void> {
        return await this.api.delete(`/upload/delete?url=${encodeURI(url)}`).then(res => res.data)
    }
}
