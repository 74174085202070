import React, { useEffect, useState } from 'react'
import { useNotify } from 'react-admin'
import Stack from '@mui/material/Stack'
import { useOrderService } from '../../../service/order-service'
import { useBoolean } from '../../../hooks/use-boolean'
import Container from '@mui/material/Container'
import LinearProgressView from '../../../common/linear-progress-view'
import Grid from '@mui/material/Unstable_Grid2'
import { useLocation, useNavigate } from 'react-router-dom'
import { Order, OrderItem } from '../../../service/types/order'
import OrderDetailsInfo from '../detail/order-details-info'
import OrderEditDetailsItems from './order-edit-details-item'

export const OrderEditView = () => {
    const { state } = useLocation()
    const [order, setOrder] = useState<Order>()
    const orderService = useOrderService()
    const loading = useBoolean(true)
    const notify = useNotify()
    const router = useNavigate()

    const fetchOrder = async () => {
        loading.onTrue()
        await orderService.get(state.id as string)
            .then(setOrder)
            .catch(() => {
                notify('Sipariş getirilirken bir hata oluştu lütfen tekrar deneyin.', { type: 'error' })
            }).finally(loading.onFalse)
    }

    useEffect(() => {
        if (state?.id) {
            void fetchOrder()
        } else {
            notify('Sipariş bilgileri bulunamadı.', { type: 'error' })
            router('/')
        }
    }, [state])

    const onItemsChange = (items: OrderItem[]) => {
        if (!order) {
            return
        }
        const update: Order = { ...order, items }
        setOrder(update)
    }

    const onShippingChange = (shipping: number) => {
        if (!order) {
            return
        }
        const update: Order = { ...order, deliveryFee: shipping }
        setOrder(update)
    }

    const onUpdateTap = async () => {
        if (!order) {
            return
        }
        const request = {
            orderId: order.id,
            items: order.items.map(it => ({ id: it.product.id, quantity: it.quantity })),
            deliveryFee: order.deliveryFee,
        }
        loading.onTrue()
        await orderService.updateOrder(request)
            .then(() => {
                notify('Sipariş güncellendi')
            })
            .catch(() => {
                notify('Sipariş güncellenirken bir hata oluştu lütfen tekrar deneyin.', { type: 'error' })
            }).finally(loading.onFalse)
    }

    if (loading.value || !order) {
        return <LinearProgressView />
    }

    return <Container maxWidth={false} sx={{ mt: 5 }}>

        <Grid container spacing={3}>
            <Grid xs={12} md={9}>
                <Stack spacing={3} direction={{ xs: 'column-reverse', md: 'column' }}>
                    <OrderEditDetailsItems items={order.items}
                                           onUpdateTap={onUpdateTap}
                                           onItemsChange={onItemsChange}
                                           shipping={order.deliveryFee ?? 0}
                                           onShippingChange={onShippingChange} />
                </Stack>
            </Grid>

            <Grid xs={12} md={3}>
                <OrderDetailsInfo
                    customer={order.customer}
                    delivery={order.driver}
                    paymentType={order.paymentType}
                    note={order.customerNote ?? ''}
                    paymentInfo={order.paymentInfo}
                    shippingAddress={order.address}
                />
            </Grid>
            <Grid xs={12} md={9}>
            </Grid>
        </Grid>
    </Container>
}