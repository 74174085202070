import { ApiAuthProvider } from './api-auth-provider'
import axios, { AxiosInstance, CancelTokenSource } from 'axios'
import { createAxios } from './base-service'
import { LiveOrderService } from './live-order-service'
import { globalConfig } from '../global-config'

export class TokenRefreshService {
    public static readonly INSTANCE = new TokenRefreshService()
    private readonly refreshInterval = 1000 * 60 * 3 * 60 // 3 hours
    private intervalId: NodeJS.Timeout | undefined
    private cancelToken: CancelTokenSource | undefined

    private api: AxiosInstance | undefined
    private readonly clientId = globalConfig.api.clientId
    private readonly clientSecret = globalConfig.api.clientSecret

    constructor() {
        setTimeout(() => {
            const session = ApiAuthProvider.INSTANCE.getSession()
            if (session) {
                this.start()
            }
        }, 1000)
    }

    public start() {
        if (this.intervalId) {
            clearInterval(this.intervalId)
        }
        this.intervalId = setInterval(() => {
            const session = ApiAuthProvider.INSTANCE.getSession()
            if (session) {
                void this.refreshToken(session.token)
            }
        }, this.refreshInterval)
    }

    public stop() {
        if (this.intervalId) {
            this.cancelToken?.cancel()
            clearInterval(this.intervalId)
        }
    }

    private async refreshToken(token: string) {
        this.cancelToken = axios.CancelToken.source()
        const request = {
            token,
            clientId: this.clientId,
            clientSecret: this.clientSecret,
        }
        if (!this.api) {
            this.api = createAxios()
        }
        await this.api.post('/admins/refresh', request, { cancelToken: this.cancelToken.token })
            .then(({ data }) => {
                ApiAuthProvider.INSTANCE.updateToken(data.token)
                const session = ApiAuthProvider.INSTANCE.getSession()
                if (session) {
                    LiveOrderService.INSTANCE.connect(session, true)
                }
                return data
            }).catch((e) => {
                if (axios.isCancel(e)) {
                    return
                }
                ApiAuthProvider.INSTANCE.setSession(undefined)
            })
    }
}