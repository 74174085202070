import { m } from 'framer-motion'
import { alpha } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { useRouter } from 'src/routes/hooks'
import { varHover } from 'src/components/animate'
import CustomPopover, { usePopover } from 'src/components/custom-popover'
import React from 'react'
import { useLogout } from 'react-admin'
import Stack from '@mui/material/Stack'
import { useUserContext } from '../../login/user-provider'

const OPTIONS = [
    {
        label: 'Ayarlar',
        linkTo: '/profile',
    },
]

export default function AccountPopover() {
    const router = useRouter()
    const popover = usePopover()
    const logout = useLogout()
    const user = useUserContext()

    const handleLogout = async () => {
        try {
            await logout()
            popover.onClose()
            router.replace('/')
        } catch (error) {
            console.error(error)
        }
    }

    const handleClickItem = (path: string) => {
        popover.onClose()
        router.push(path)
    }

    return (
        <>
            <IconButton
                component={m.button}
                whileTap="tap"
                whileHover="hover"
                variants={varHover(1.05)}
                onClick={popover.onOpen}
                sx={{
                    width: 40,
                    height: 40,
                    background: (theme) => alpha(theme.palette.grey[500], 0.08),
                    ...(popover.open && {
                        background: (theme) =>
                            `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
                    }),
                }}
            >
                <Avatar
                    src={user?.name}
                    alt={user?.name}
                    sx={{
                        width: 36,
                        height: 36,
                        border: (theme) => `solid 2px ${theme.palette.background.default}`,
                    }}
                >
                    {user?.name?.charAt(0).toUpperCase()}
                </Avatar>
            </IconButton>

            <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>
                <Box sx={{ p: 2, pb: 1.5 }}>
                    <Typography variant="subtitle2" noWrap>
                        {user?.name}
                    </Typography>
                </Box>
                <Divider sx={{ borderStyle: 'dashed' }} />

                <Stack sx={{ p: 1 }}>
                    {OPTIONS.map((option) => (
                        <MenuItem
                            key={option.label}
                            onClick={() => {
                                handleClickItem(option.linkTo)
                            }}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
                </Stack>

                <Divider sx={{ borderStyle: 'dashed' }} />

                <MenuItem
                    onClick={async () => {
                        await handleLogout()
                    }}
                    sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
                >
                    Çıkış Yap
                </MenuItem>
            </CustomPopover>
        </>
    )
}
